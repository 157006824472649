import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./siteMap.css";

const SiteMap = () => {
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  //load animation
  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  const dispatch = useDispatch();

  return (
    <div className={isComponentLoaded ? "sitemap" : "sitemap-not-rendered"}>
      <div className="sitemap-box">
        <h2 className="color-blue" style={{ marginLeft: "2rem" }}>
          Site Map{" "}
        </h2>
      </div>
      <div className="link-wrapper">
        <ul>
          <h3>
            <Link to="/uaichome">UAIC Home</Link>
          </h3>

          <br></br>

          <h3>
            <Link to="/uaichome/aboutus">About Us</Link>
          </h3>

          <br></br>

          <h3>
            <Link to="/uaichome/insurance">Insurance</Link>
          </h3>

          <br></br>

          <h3>
            <Link to="/uaichome/site-map">Site Map </Link>
          </h3>
          <br></br>

          <h3>
            <Link to="/uaichome/site-requirements">Site Requirements </Link>
          </h3>
          <br></br>

          <h3>
            <Link to="/uaichome/privacy-policy">Privacy Policy </Link>
          </h3>

          <br></br>

          <h3>Agent Login</h3>
          <ul>
            <li>
              <p
                className="site-map-link"
                onClick={() => {
                  dispatch({
                    type: "OPEN_AGENTS_LOGIN_MENU",
                  });
                }}
              >
                Florida
              </p>
            </li>
            <li>
              <p
                className="site-map-link"
                onClick={() => {
                  dispatch({
                    type: "OPEN_AGENTS_LOGIN_MENU",
                  });
                }}
              >
                Georgia
              </p>
            </li>
            <li>
              <p
                className="site-map-link"
                onClick={() => {
                  dispatch({
                    type: "OPEN_AGENTS_LOGIN_MENU",
                  });
                }}
              >
                Texas
              </p>
            </li>
          </ul>
          <br></br>
          <h3>
            <Link to="/uaichome/contact" title="Contact UAIC">
              Contact Us
            </Link>
          </h3>
          <ul>
            <li>
              <Link
                to="/uaichome/contact"
                onClick={() => {
                  dispatch({
                    type: "SET_SELECTED_CONTACT_TAB",
                    payload: "FLORIDA",
                  });
                }}
              >
                Florida
              </Link>
            </li>
            <li>
              <Link
                to="/uaichome/contact"
                onClick={() => {
                  dispatch({
                    type: "SET_SELECTED_CONTACT_TAB",
                    payload: "GEORGIA",
                  });
                }}
              >
                Georgia
              </Link>
            </li>
            <li>
              {" "}
              <Link
                to="/uaichome/contact"
                onClick={() => {
                  dispatch({
                    type: "SET_SELECTED_CONTACT_TAB",
                    payload: "TEXAS",
                  });
                }}
              >
                Texas
              </Link>
            </li>
          </ul>
          <br></br>
          <h3>Community</h3>

          <ul>
            <li>
              {" "}
              <Link to="/uaichome/careers">Careers</Link>
            </li>
            <li>
              {" "}
              <Link to="/uaichome/careers">Apply Online</Link>
            </li>
            <li>
              {" "}
              <Link
                onClick={() => {
                  dispatch({
                    type: "SET_SELECTED_CAREERS_TAB",
                    payload: "Benefits",
                  });
                  window.scrollTo({
                    top: 100,
                    behavior: "smooth",
                  });
                }}
                to="/uaichome/careers"
              >
                <p>Benefits</p>
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  dispatch({
                    type: "SET_SELECTED_CAREERS_TAB",
                    payload: "Diversity",
                  });
                  window.scrollTo({
                    top: 100,
                    behavior: "smooth",
                  });
                }}
                to="/uaichome/careers"
              >
                <p>Diversity</p>
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  dispatch({
                    type: "SET_SELECTED_CAREERS_TAB",
                    payload: "FAQ",
                  });
                  window.scrollTo({
                    top: 100,
                    behavior: "smooth",
                  });
                }}
                to="/uaichome/careers"
              >
                <p>FAQ</p>
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  dispatch({
                    type: "SET_SELECTED_CAREERS_TAB",
                    payload: "Job_Fairs",
                  });
                  window.scrollTo({
                    top: 100,
                    behavior: "smooth",
                  });
                }}
                to="/uaichome/careers"
              >
                <p>Job Fairs</p>
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  dispatch({
                    type: "SET_SELECTED_CAREERS_TAB",
                    payload: "Accounting",
                  });
                  window.scrollTo({
                    top: 100,
                    behavior: "smooth",
                  });
                }}
                to="/uaichome/careers"
              >
                <p>Accounting</p>
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  dispatch({
                    type: "SET_SELECTED_CAREERS_TAB",
                    payload: "Claims",
                  });
                  window.scrollTo({
                    top: 100,
                    behavior: "smooth",
                  });
                }}
                to="/uaichome/careers"
              >
                <p>Claims</p>
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  dispatch({
                    type: "SET_SELECTED_CAREERS_TAB",
                    payload: "Corporate_&_Executive",
                  });
                  window.scrollTo({
                    top: 100,
                    behavior: "smooth",
                  });
                }}
                to="/uaichome/careers"
              >
                <p>Corporate & Executive</p>
              </Link>
            </li>
            <li>
              {" "}
              <Link
                onClick={() => {
                  dispatch({
                    type: "SET_SELECTED_CAREERS_TAB",
                    payload: "Information_Technology",
                  });
                  window.scrollTo({
                    top: 100,
                    behavior: "smooth",
                  });
                }}
                to="/uaichome/careers"
              >
                <p>Information Technology</p>
              </Link>
            </li>
            <li>
              {" "}
              <Link
                onClick={() => {
                  dispatch({
                    type: "SET_SELECTED_CAREERS_TAB",
                    payload: "Underwriting",
                  });
                  window.scrollTo({
                    top: 100,
                    behavior: "smooth",
                  });
                }}
                to="/uaichome/careers"
              >
                <p>Underwriting</p>
              </Link>
            </li>
          </ul>
          <br></br>

          {/* <h3>AFFILIATE COMPANIES</h3>
          <ul>
            <li>United Premium Finance Company</li>
          </ul> */}
        </ul>
      </div>
    </div>
  );
};

export default SiteMap;

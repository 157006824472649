import React, { useEffect, useState } from "react";
import "./newUser.css";
import { passwordStrength } from "check-password-strength";
import { LazyLoadImage } from "react-lazy-load-image-component";
import selectCorrectUrls from "../../selectCorrectUrls";

const NewUserAgent = () => {
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  const [step, setStep] = useState(0);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checkAgainPassword, setCheckAgainPassword] = useState("");
  const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);
  const [goPaperLess, setGoPaperLess] = useState(null);

  //load animation
  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  useEffect(() => {
    if (password !== checkAgainPassword) {
      setPasswordsDontMatch(false);
    } else setPasswordsDontMatch(true);
  }, [password, checkAgainPassword]);

  const chekcPassword = () => {
    const passwordInfo = passwordStrength(password);

    return (
      <ul style={{ listStyle: "none", paddingLeft: "10px" }}>
        <li
          style={
            passwordInfo.length >= 10
              ? { color: "green", display: "flex" }
              : { color: "red" }
          }
        >
          {passwordInfo.length >= 10 && <p style={{ marginRight: "5px" }}>✓</p>}
          <p>Minimum 10 Characters</p>
        </li>
        <li
          style={
            passwordInfo.contains.includes("lowercase")
              ? { color: "green", display: "flex" }
              : { color: "red" }
          }
        >
          {passwordInfo.contains.includes("lowercase") && (
            <p style={{ marginRight: "5px" }}>✓</p>
          )}
          <p>Lowercase</p>
        </li>
        <li
          style={
            passwordInfo.contains.includes("uppercase")
              ? { color: "green", display: "flex" }
              : { color: "red" }
          }
        >
          {passwordInfo.contains.includes("uppercase") && (
            <p style={{ marginRight: "5px" }}>✓</p>
          )}
          <p>Uppercase</p>
        </li>
        <li
          style={
            passwordInfo.contains.includes("number")
              ? { color: "green", display: "flex" }
              : { color: "red" }
          }
        >
          {passwordInfo.contains.includes("number") && (
            <p style={{ marginRight: "5px" }}>✓</p>
          )}
          <p>Number</p>
        </li>
        <li
          style={
            passwordInfo.contains.includes("symbol")
              ? { color: "green", display: "flex" }
              : { color: "red" }
          }
        >
          {passwordInfo.contains.includes("symbol") && (
            <p style={{ marginRight: "5px" }}>✓</p>
          )}
          <p>Symbol</p>
        </li>
      </ul>
    );
  };

  const renderSteps = () => {
    if (step === 0) {
      return (
        <div className={isComponentLoaded ? "newUser" : "newUser-not-rendered"}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3rem",
            }}
          >
            <div className="create-account-div">
              <div>
                <LazyLoadImage
                  style={{ width: "100%" }}
                  src="/uaichome/pictures/create-my-account.png"
                ></LazyLoadImage>
                <h2 style={{ marginTop: "1rem" }}>Use my policy number.</h2>
                <hr></hr>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setStep(1);
                  }}
                >
                  <div style={{ marginLeft: "20px" }}>
                    <label>State</label>
                    <br></br>
                    <select
                      name="state"
                      id="state"
                      required
                      style={{ padding: "10px", width: "200px" }}
                    >
                      <option value="" disabled selected hidden>
                        Choose your State
                      </option>
                      <option>Florida</option>
                      <option>Texas</option>
                      <option>Georgia</option>
                    </select>
                  </div>{" "}
                  <br></br>
                  <div style={{ marginLeft: "20px" }}>
                    <label>Policy Number:</label>
                    <br></br>
                    <input
                      type="text"
                      name="policyno"
                      id="policyno"
                      autoComplete="on"
                      required
                      placeholder="Policy Number"
                      style={{ padding: "10px", width: "200px" }}
                    ></input>
                  </div>
                  <br></br>
                  <div style={{ marginLeft: "20px" }}>
                    <label>Mailing Zip Code*:</label>
                    <br></br>
                    <input
                      type="text"
                      name="mailing-zip"
                      id="mailing-zip"
                      autoComplete="on"
                      required
                      placeholder="Zip Code"
                      style={{ padding: "10px", width: "200px" }}
                    ></input>
                  </div>
                  <br></br>
                  <div style={{ marginLeft: "20px" }}>
                    <label>Date of Birth*:</label>
                    <br></br>
                    <input
                      type="text"
                      name="date-of-birth"
                      id="date-of-birth"
                      autoComplete="on"
                      required
                      style={{ padding: "10px", width: "200px" }}
                    ></input>
                  </div>
                  <br></br>
                  <button
                    type="submit"
                    id="polsubmit"
                    style={{ marginLeft: "20px" }}
                    className="home-page-card-button"
                    onClick={() => {
                      window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                      });
                    }}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
            <div>
              <div style={{ width: "300px", position: "sticky", top: "100px" }}>
                <h2>Registration Benefits</h2>
                <hr></hr>
                <ul>
                  <li>Report a New Claim</li>
                  <li>Check Status on Claim</li>
                  <li>Send message to Adjuster</li>
                </ul>
                <p>
                  <strong>Note: </strong>You should have a policy with United
                  Auto to complete registration
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (step === 1) {
      return (
        <div>
          <div
            className={isComponentLoaded ? "newUser" : "newUser-not-rendered"}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "3rem",
              }}
            >
              <div className="create-account-div">
                <div>
                  <h1>Hello "name of the person"</h1>
                  <br></br>
                  <h2 style={{ marginTop: "1rem" }}>
                    Create Log In credentials.
                  </h2>
                  <hr></hr>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      setStep(2);
                    }}
                  >
                    <div style={{ marginLeft: "20px" }}>
                      <label>Policyholder Email</label>
                      <br></br>
                      <input
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        type="email"
                        name="email"
                        id="email"
                        value={email}
                        required
                        style={{ padding: "10px", width: "200px" }}
                        placeholder="jondoe@gmail.com"
                      ></input>
                    </div>{" "}
                    <br></br>
                    <div style={{ marginLeft: "20px" }}>
                      <label>Choose Desired Password:</label>
                      <br></br>
                      <input
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        value={password}
                        type="password"
                        name="password"
                        id="password"
                        autoComplete="on"
                        required
                        placeholder="password"
                        style={{ padding: "10px", width: "200px" }}
                      ></input>
                      <label style={{ marginLeft: "1rem" }}>
                        {passwordStrength(password).value}
                      </label>
                      <label>{chekcPassword()}</label>
                    </div>
                    <div style={{ marginLeft: "20px" }}>
                      <label>Confirm Password:</label>
                      <br></br>
                      <input
                        onChange={(e) => {
                          setCheckAgainPassword(e.target.value);
                        }}
                        value={checkAgainPassword}
                        type="password"
                        name="confpassword"
                        id="confpassword"
                        autoComplete="on"
                        required
                        placeholder="password"
                        style={{ padding: "10px", width: "200px" }}
                      ></input>
                      <label
                        style={
                          passwordsDontMatch
                            ? { color: "green", marginLeft: "1rem" }
                            : { color: "red", marginLeft: "1rem" }
                        }
                      >
                        {checkAgainPassword !== "" &&
                          (passwordsDontMatch
                            ? "Passwords Match!"
                            : "Passwords Don't Match")}
                      </label>
                    </div>
                    <br></br>
                    <div style={{ marginLeft: "20px" }}>
                      <p>Choose Paperless:</p>
                      <form
                        onChange={(e) => {
                          if (e.target.value === "Yes") {
                            setGoPaperLess(true);
                          } else setGoPaperLess(false);
                        }}
                      >
                        <label for="Yes">
                          <input
                            type="radio"
                            id="Yes"
                            name="goPaperLess"
                            value="Yes"
                          />
                          Yes
                        </label>

                        <label for="No">
                          <input
                            type="radio"
                            id="No"
                            name="goPaperLess"
                            value="No"
                          />
                          No
                        </label>
                      </form>
                    </div>
                    <br></br>
                    <div>
                      {goPaperLess && (
                        <div style={{ padding: "1rem" }}>
                          <strong>
                            Agreement to Receive Documents Electronically
                          </strong>

                          <p>
                            I am the named insured on an insurance policy with
                            United Automobile Insurance Company (UAIC) and have
                            agreed to receive my policy and policy notices
                            delivered to me in electronic format rather than
                            paper form. I understand that I will receive emails
                            notifying me that my policy and policy notices have
                            been delivered to a secure internet portal on
                            www.uaig.net, which I may access with my username
                            and password.
                          </p>
                          <p>
                            I understand that this agreement applies to all
                            documents related to my policy with UAIC, including,
                            but not limited to:
                          </p>
                          <ul>
                            <li>Declaration page</li>
                            <li>Policy</li>
                            <li>Endorsements</li>
                            <li>Privacy policies</li>
                            <li>
                              All disclosures normally required to be made by
                              law
                            </li>
                          </ul>
                          <p>
                            I understand that the above documents may not be
                            available in electronic format or that applicable
                            state law may specify a different method of
                            delivery. In that case, the above documents may be
                            sent to me in paper copy.
                          </p>
                          <p>
                            I understand that when I agree to receive my policy
                            and policy notices in electronic format, I will not
                            be receiving such documents in paper form, unless
                            the document is unavailable in electronic form. I
                            can decide at any time to not receive my policy and
                            policy notices in electronic format and begin
                            receiving such documents in paper copy by
                            withdrawing this consent. I may withdraw this
                            consent by logging into the secure portal at
                            www.uaig.net and changing my{" "}
                            <strong>Paperless</strong> preference setting within
                            the <strong>Edit Profile</strong> section of site.
                            Please allow 24 hours for UAIC to process your
                            request.
                          </p>
                          <p>
                            I may from time to time desire a single paper copy
                            of my active policy or active policy notices. In
                            that event, I may obtain one by calling UAIC at
                            (888) 987-UAIC (8242) or requesting a copy in
                            writing at P.O. Box 694140, Miami, FL 33269. If I
                            require more than 2 paper copies of electronic
                            documents in a current policy period, UAIC may
                            suggest that I discontinue the electronic service
                            and request that I resume receiving paper copies. No
                            fees will be charged for receiving documents in
                            electronic or paper format.
                          </p>
                          <p>
                            For purposes of receiving policies and policy
                            documents electronically, I must provide my e-mail
                            address on{" "}
                            <a href="http://www.uaig.net">www.uaig.net</a>. If I
                            change my email address, I will notify UAIC as soon
                            as possible by updating my e-mail address on the
                            secure portal. It is my responsibility to keep my
                            email address with UAIC up to date, and if I provide
                            an invalid or out of date email address to UAIC I
                            acknowledge that I may not receive electronic
                            documents that were intended for my review.
                          </p>
                          <p>
                            I am the owner of a personal computer or other
                            similar device or have regular access to a personal
                            computer or similar device which provides me with
                            access to www.uaig.net and on which I can retrieve
                            and retain electronic mail notices from UAIC and
                            retrieve and retain electronic copies of my policy
                            and policy notices. This computer must meet the
                            following requirements to access and retain
                            electronic documents:
                          </p>

                          <table
                            cellpadding="2"
                            cellspacing="0"
                            border="1"
                            id="Table1"
                            width="100%"
                          >
                            <tr>
                              <td>Operating Systems:</td>
                              <td>
                                Windows&reg; XP, Windows Vista&reg;, Windows
                                7&reg;{" "}
                              </td>
                            </tr>
                            <tr>
                              <td>Browsers:</td>
                              <td>
                                Final release versions of Internet Explorer 6.0
                                or above (Windows only); Safari&#8482; 3.0 or
                                above (Mac only)
                              </td>
                            </tr>
                            <tr>
                              <td>PDF Reader:</td>
                              <td>
                                Acrobat&reg; or similar software may be required
                                to view and print PDF files
                              </td>
                            </tr>
                            <tr>
                              <td>Screen Resolution:</td>
                              <td>800 x 600 minimum</td>
                            </tr>
                            <tr>
                              <td>Enabled Security Settings:</td>
                              <td>Allow per session cookies</td>
                            </tr>
                          </table>

                          <p>
                            **These minimum requirements are subject to change.
                            If these requirements change, you will be asked to
                            re-accept the disclosure. Pre-release (e.g. beta)
                            versions of operating systems and browsers are not
                            supported.
                          </p>

                          <ul>
                            <li>
                              I agree that when I receive emails notifying me
                              that documents have been delivered to my online
                              account, I will faithfully and promptly read the
                              documents and notify UAIC if the information is
                              inaccurate or I do not understand the information
                              provided. I have received and reviewed UAIC's
                              Privacy Policy.
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                    <br></br>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        style={{ marginLeft: "20px" }}
                        className="home-page-card-button"
                        onClick={() => {
                          setStep(0);
                          window.scrollTo({
                            top: 100,
                            behavior: "smooth",
                          });
                        }}
                      >
                        Go Back
                      </button>
                      <button
                        type="submit"
                        id="polsubmit"
                        style={{ marginRight: "20px" }}
                        className="home-page-card-button"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div>
                <div
                  style={{ width: "300px", position: "sticky", top: "100px" }}
                >
                  <h2>Registration Benefits</h2>
                  <hr></hr>
                  <ul>
                    <li>Report a New Claim</li>
                    <li>Check Status on Claim</li>
                    <li>Send message to Adjuster</li>
                  </ul>
                  <p>
                    <strong>Note: </strong>You should have a policy with United
                    Auto to complete registration
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <div className="newUser-box">
        <h2 className="color-blue" style={{ marginLeft: "2rem" }}>
          Create My Account!
        </h2>
      </div>
      <div className="new-user-iframe">
        <iframe
          style={{
            border: "none",
            overscrollBehaviorY: "none",
          }}
          title="Lein holder search"
          width="95%"
          height="700"
          scrolling="no"
          src={`${
            selectCorrectUrls().insuredPortal
          }/insurednew/insured/rnu/registeruser`}
        ></iframe>
      </div>

      {/* {renderSteps()} */}
    </div>
  );
};

export default NewUserAgent;

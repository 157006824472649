import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import selectCorrectUrls from "../../selectCorrectUrls";
import "./requestHelp.css";

const RequestedHelp = () => {
  const dispatch = useDispatch();
  const requestHelpReason = useSelector(
    (state) => state.requestHelpReasonReducer
  );
  const agentInfoReducer = useSelector((state) => state.agentInfoReducer);

  const returnCorrectedPhoneNumber = () => {
    if (agentInfoReducer.AgentPhone) {
      let areaCode = agentInfoReducer.AgentPhone.substring(0, 3);
      let first3 = agentInfoReducer.AgentPhone.substring(3, 6);
      let last4Digit = agentInfoReducer.AgentPhone.substring(6, 10);

      return areaCode + "-" + first3 + "-" + last4Digit;
    }
  };
  const displayCorrectMessage = () => {
    if (requestHelpReason === "cancel_policy") {
      return (
        <h1>
          In order to request cancellation on your policy, Please contact your
          agent at{" "}
          <a href={`tel:${agentInfoReducer.AgentPhone}`}>
            {returnCorrectedPhoneNumber(agentInfoReducer.AgentPhone)}
          </a>
        </h1>
      );
    } else if (requestHelpReason === "change_policy") {
      return (
        <h1>
          In order to request a change on your policy, Please contact your agent
          at{" "}
          <a href={`tel:${agentInfoReducer.AgentPhone}`}>
            {returnCorrectedPhoneNumber(agentInfoReducer.AgentPhone)}
          </a>{" "}
          or <br />
          <a href={`${selectCorrectUrls().insuredPortal}/insurednew`}>
            click here
          </a>{" "}
          to endorse the policy on your own.
        </h1>
      );
    } else if (requestHelpReason === "lien_holder") {
      return (
        <div className="lien_holder_requested_help_page_container">
          <h1>
            Please contact your agent at{" "}
            <a href={`tel:${agentInfoReducer.AgentPhone}`}>
              {returnCorrectedPhoneNumber(agentInfoReducer.AgentPhone)}
            </a>{" "}
            or <br />
            <a
              href={`https://mypolicy.uaig.net/insurednew/insured/lien/index#1`}
            >
              click here
            </a>{" "}
            to verify insurance for lien holder or <br />
            <a href={`https://mypolicy.uaig.net/insurednew/`}>click here</a> to
            go to self service portal.
          </h1>
        </div>
      );
    } else {
      return <h1>The help request has been submitted to company</h1>;
    }
  };
  return (
    <>
      {/* <span className="requested-help-bread-crumbs">
        <Link to="/">Home</Link> /{" "}
        <Link to="/uaichome/contact">Contact Us</Link>
      </span> */}
      <div className="requested-help-container">{displayCorrectMessage()}</div>
      <div className="requested-help-buttons-container">
        <Link to="/">
          <button className="requested-help-buttons">Back to Home Page</button>
        </Link>
        <Link to="/uaichome/contact">
          <button
            onClick={() => {
              dispatch({
                type: "SET_REQUEST_HELP_REASON",
                payload: "",
              });
            }}
            className="requested-help-buttons"
          >
            Continue on Contact Page
          </button>
        </Link>
      </div>
    </>
  );
};

export default RequestedHelp;

import React, { useState, useEffect } from "react";
import "./diversity.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Diversity = () => {
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);

  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  return (
    <div className={isComponentLoaded ? "Diversity" : "Diversity-not-rendered"}>
      <div className="header-picture">
        <LazyLoadImage
          style={{ width: "100%" }}
          alt="career"
          src="/uaichome/pictures/diversity2.png"
          effect="blur"
        ></LazyLoadImage>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <h2 className="blue-color">Diversity</h2>
        <hr></hr>
        <div>
          <p>
            We believe that a key component of making UAIC a great place to work
            is each of us understanding and welcoming our differences and
            similarities--
            <strong>
              people with different backgrounds and experiences bring important
              perspectives to UAIC's business challenges and thinking.
            </strong>{" "}
            Crucial to sustaining UAIC's unique culture is having an
            organization where everyone who works here feels like they are part
            of one community.
          </p>
          <br></br>
          <p>
            Here in UAIC, we think that hiring people who bring added diversity
            to our work force is important to our future. With a diverse work
            force, we all benefit--and so do our customers.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Diversity;

import { isMobile } from "react-device-detect";
import ReactGA from "react-ga4";

const GA = (action, label) => {
  if (isMobile) {
    ReactGA.event({
      category: "UAIC_HOME",
      action: `${label}_Mobile`,
      label: `${action} - Mobile`,
    });
  } else {
    ReactGA.event({
      category: "UAIC_HOME",
      action: label,
      label: action,
    });
  }
};

export default GA;

import React, { useEffect, useState } from "react";
import "./findanagent.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import GA from "../functions/GA";
import Axios from "axios";
import selectCorrectUrls from "../../selectCorrectUrls";
import { Link } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";
import { css } from "@emotion/react";
import { useHistory } from "react-router-dom";
import { setRef } from "@material-ui/core";

const FindAnAgentComponent = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [zipCode, setZipCode] = useState("");
    const [agentzipCode, setAgentZipCode] = useState("");
    const [agent_1_info, setAgent1Info] = useState("")
    const [agent_2_info, setAgent2Info] = useState("")
    const [agent_3_info, setAgent3Info] = useState("")
    const [displayAgents, setDisplayAgents] = useState(false);
    const [isMessage, setIsMessage] = useState(false);
    const [message, setMessage] = useState(false);
    const [submitRequest, setSubmitRequest] = useState(false)
    const [isErrorMessage, setIsErrorMessage] = useState(false);
    const [zipCodeError, setZipCodeError] = useState(false);
    const [displayNoAgentsFound, setNoAgentsFound] = useState(false)
    const [requestAgentTimerRunning, isRequestAgentTimerRunning] = useState(false)
    const [requestAgentTimerSeconds, setRequestAgentTimerSeconds] = useState(0)
    const [buyNowURl, setBuyNowUrl]= useState('');



   
    useEffect(() => {
      if (isErrorMessage === true) {
        setTimeout(() => {
          setIsErrorMessage(false);
        }, 5000);
      } else if (isMessage === true) {
        setTimeout(() => {
          setIsMessage(false);
        }, 5000);
      }
    }, [isErrorMessage, isMessage]);
    
    
    const [config, setConfig] = useState({
      headers: {
        "Content-Type": "text/html",
      },
      responseType: "text",
    });

    const [isComponentLoaded, setIsComponentLoaded] = useState(false);
    //load animation
    useEffect(() => {
      setIsComponentLoaded(true);
    }, []);

    const override = css`
    display: flex;
    margin: auto;
    color: white;
    margin-top: -7px;
  `;

    const agentLocatorZipCodeReducer = useSelector((state) => state.agentLocatorZipCodeReducer);
    const agentLocatorInfoReducer = useSelector((state) => state.agentLocatorInfoReducer);

    function getUrlParams(search) {
      const hashes = search.slice(search.indexOf("?") + 1).split("&");
      const params = {};
      hashes.map((hash) => {
        const [key, val] = hash.split("=");
        params[key] = decodeURIComponent(val);
      });
      return params;
    }


    if (getUrlParams(window.location.hash).zip !== "undefined" && getUrlParams(window.location.hash).zip !== undefined) {

      const zipcodeType = typeof(parseInt(getUrlParams(window.location.hash).zip));
      const zipcodeLength = (getUrlParams(window.location.hash).zip.length);

      if(zipcodeType === 'number' && zipcodeLength == 5){
        dispatch({
          type: "SET_AGENT_LOCATOR_ZIP",
          payload: getUrlParams(window.location.hash).zip,
        });
       // setZipCodeError(false)
        console.log("REDUCER WAS SET WITH URL AND ZIP CODE WAS EQUAL TO 5")
      
      }

      else{
        console.log("REDUCER WAS NOT SET AND ZIP CODE WAS NOT EQUAL TO 5")
        //setDisplayAgents(false)
        if(agentzipCode.length === 5){
          dispatch({
            type: "SET_AGENT_LOCATOR_ZIP",
            payload: agentzipCode,
          });
     
        }else{
          dispatch({
            type: "SET_AGENT_LOCATOR_ZIP",
            payload: null,
          });
          dispatch({
            type: "SET_AGENT_LOCATOR_INFO",
            payload: null,
          });
        }
      
      
      //  setZipCodeError(true)
     }
    
    }else{
      dispatch({
        type: "SET_AGENT_LOCATOR_ZIP",
        payload: null,
      });
      dispatch({
        type: "SET_AGENT_LOCATOR_INFO",
        payload: null,
      });
    }

    const errorMessageComponent = () => {
      return (
        <div
          className={
            isErrorMessage ? "error-message-shown faa-message" : "error-message-hidden"
          }
        >
          <div></div>
          <h3>{message}</h3>
        </div>
      );
    };
  
    const messageComponent = () => {
      return (
        <div className={isMessage ? "message-shown faa-message" : "message-hidden"}>
          <div></div>
          <h3>{message}</h3>
        </div>
      );
    };


   
    useEffect(()=>{
      console.log("INSIDE USE EFFECT")

      selectCorrectHrefForTheZip()



      if(agentLocatorZipCodeReducer !== null || agentzipCode.length === 5 ){     
        console.log("INSIDE IF STATEMENT")
      let url = `${
        selectCorrectUrls().quotePortal
      }/uaicins/application/lead/agentdata?zipcode=${agentLocatorZipCodeReducer === null ? agentzipCode : agentLocatorZipCodeReducer}`;
    
      Axios.get(url, config)
        .then((response) => {

          console.log("response ===> " )
          console.log(response)
          setIsLoading(true);
        
        setTimeout(() => {
         
          let agent1Info = response.data.AGENT1
          let agent2Info = response.data.AGENT2
          let agent3Info = response.data.AGENT3
  
          let data = response.data
  
          if(data === undefined && data.includes("Fatal")){
            dispatch({
              type: "SET_AGENT_LOCATOR_ZIP",
              payload: null,
            });
            dispatch({
              type: "SET_AGENT_LOCATOR_INFO",
              payload: "NO AGENTS FOUND",
            });
          }else{
            if(agent1Info !== undefined ){
              setAgent1Info(agent1Info)
            }
            if(agent2Info !== undefined ){
              setAgent2Info(agent2Info)
            }
            if(agent3Info !== undefined ){
              setAgent3Info(agent3Info)
            }
            dispatch({
              type: "SET_AGENT_LOCATOR_INFO",
              payload: {
                agent1: agent1Info,
                agent2: agent2Info,
                agent3: agent3Info,
  
              },
            });
            setIsErrorMessage(false);
          }
  
        
         
  
            if(response.data.AGENT1.agency_name === 'No Agents Found'){
              console.log("No Agents Found")
              setNoAgentsFound(true)
            }else {
              setNoAgentsFound(false)
            }
  
            setIsLoading(false);
        }, 3000);
       
        
        })
        .catch((e) => {
          console.log("INSIDE ERROR")
          console.log(e)
          setIsErrorMessage(true);
          setMessage("System Failure: Please try after some time.");
          setIsLoading(false)
          isRequestAgentTimerRunning(true)
          dispatch({
            type: "SET_AGENT_LOCATOR_ZIP",
            payload: null,
          });
          dispatch({
            type: "SET_AGENT_LOCATOR_INFO",
            payload: "NO AGENTS FOUND",
          });
    
          // console.log(e);
        });
      }

      else{
        console.log("THE ZIP CODE REDUCER IS EMPTY")
        console.log("FELL IN THE ELSE")
       
       
      }
    
    },[agentLocatorZipCodeReducer,submitRequest])


    useEffect(()=>{
      console.log("SUBMIT WAS REQUESTED USEEFFECT")
      if(submitRequest){
        window.location.hash = '/uaichome/FindAnAgent?zip='+ agentzipCode
      }

      
    },[submitRequest])



    const selectCorrectHrefForTheZip = (e) => {
      let firstTwo = agentzipCode.substring(0, 2);
      console.log("agentLocatorZipCodeReducer")
   if(agentzipCode === '' && getUrlParams(window.location.hash).zip !== undefined){
        firstTwo = getUrlParams(window.location.hash).zip.substring(0, 2);
      }
      console.log("FIRST TWO ARE BELOW")
      console.log(firstTwo)
      console.log("whoa")
   
     // const firstTwo = getUrlParams(window.location.hash).zip.substring(0, 2);
     
      if (
        firstTwo === "73" ||
        firstTwo === "75" ||
        firstTwo === "76" ||
        firstTwo === "77" ||
        firstTwo === "78" ||
        firstTwo === "88" ||
        firstTwo === "79"
      ) {
        console.log(`${
          selectCorrectUrls().quotePortal
        }/uaicins/txsale/quick/start/UAIS?campaign=agt`);
        setBuyNowUrl(`${
          selectCorrectUrls().quotePortal
        }/uaicins/txsale/quick/start/UAIS?campaign=agt`)
       return `${
          selectCorrectUrls().quotePortal
        }/uaicins/txsale/quick/start/UAIS?campaign=agt`;
      } else if (firstTwo === "32" || firstTwo === "33" || firstTwo === "34") {
        console.log( `${
          selectCorrectUrls().quotePortal
        }/uaicins/sale/quick/index/UAI?campaign=agt`);
        setBuyNowUrl(`${
          selectCorrectUrls().quotePortal
        }/uaicins/sale/quick/index/UAI?campaign=agt`)
        return `${
          selectCorrectUrls().quotePortal
        }/uaicins/txsale/quick/start/UAIS?campaign=agt`;
      } else {
        setBuyNowUrl('')
        return "javascript:void(0)";
      }
    };

    
   
    
    const getTodaysDate = () => {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear();
    
        today = yyyy + "/" + mm + "/" + dd;
    
        return today;
      };
    
    const getCurrentTime = () => {
    var d = new Date(); // for now
    d.getHours(); // => 9
    d.getMinutes(); // =>  30
    d.getSeconds();

    let time = new Date().toTimeString().slice(0, 8);

    return time;
    };

    function numbersOnly(e) {
        var key = e.which || e.keyCode; //use event.which if it's truthy, and default to keyCode otherwise
        // Allow: backspace, delete, tab, and enter
        var controlKeys = [8, 9, 13];
        //for mozilla these are arrow keys
        //if ($.browser.mozilla) controlKeys = controlKeys.concat([37, 38, 39, 40]);
    
        // Ctrl+ anything or one of the conttrolKeys is valid
        var isControlKey =
          e.ctrlKey || controlKeys.join(",").match(new RegExp(key));
        if (isControlKey) {
          return;
        }
        // stop current key press if it's not a number
        if (!(48 <= key && key <= 57)) {
          e.preventDefault();
          return;
        }
      }
      

   
    // const checkZipCodeValidation = () => {
    //     const firstTwo = zipCode.substring(0, 2);
    
    //     if (
    //       firstTwo === "73" ||
    //       firstTwo === "75" ||
    //       firstTwo === "76" ||
    //       firstTwo === "77" ||
    //       firstTwo === "78" ||
    //       firstTwo === "88" ||
    //       firstTwo === "79" ||
    //       firstTwo === "32" ||
    //       firstTwo === "33" ||
    //       firstTwo === "34"
    //     ) {
    //       Axios.post(
    //         `${
    //           selectCorrectUrls().insuredPortal
    //         }/insurednew/insured/sqzl/index?zipcode=${zipCode}&status=Y&time=${getCurrentTime()}&date=${getTodaysDate()}`
    //       )
    //         .then((response) => {})
    //         .catch((e) => {
    //           // console.log(e);
    //         });
    //     } else {
    //       Axios.post(
    //         `${
    //           selectCorrectUrls().insuredPortal
    //         }/insurednew/insured/sqzl/index?zipcode=${zipCode}&status=N&time=${getCurrentTime()}&date=${getTodaysDate()}`
    //       )
    //         .then((response) => {})
    //         .catch((e) => {
    //           // console.log(e);
    //         });
    //     }
    // };
    const openInNewTab = (url) => {
      window.open(url, "_blank").focus();
    };

    const displayNoAgents = () =>{

      return <>
        <center>

           <h3 className="no-agents-found">No Agents Found in <span style={{textDecoration:"underline",color:"black"}}>{agentLocatorZipCodeReducer} </span></h3>
        </center>
      
      </>
    }


    const displayAgentInfo = () => {
return(
  <>  
   {displayNoAgentsFound ? displayNoAgents() : <> <div style={{width:"fit-content",margin:"2em auto", fontWeight:"bold"}}>

             <h2><b>({agent_2_info.city === false && agent_3_info.city === false  ? "1" : agent_3_info.city === false ? "2":"3" }) Agents Found Near <span className="blue-color" style={{textDecoration:"underline"}}>{agentLocatorZipCodeReducer}</span> </b> </h2>
       </div>
      <div className="find-an-agent-div" style={{display:"flex", maxWidth:"1000px",margin:"0 auto" }}>
   
       {agent_1_info.agency_name !== false && agent_1_info.city !== false ? displayLocation(`${agent_1_info && agent_1_info.agency_name}`,``, `${agent_1_info && agent_1_info.address1}`, `${agent_1_info && agent_1_info.city}`, `${agent_1_info && agent_1_info.state}`, `${agent_1_info && agent_1_info.zip}`,`${agent_1_info && agent_1_info.phone}`,"","",0,"1.5 Miles"): ""}
       {agent_2_info.address1 !== false && agent_2_info.city !== false  ? displayLocation(`${agent_2_info && agent_2_info.agency_name}`,``, `${agent_2_info && agent_2_info.address1}`, `${agent_2_info && agent_2_info.city}`, `${agent_2_info && agent_2_info.state}`, `${agent_2_info && agent_2_info.zip}`,`${agent_2_info && agent_2_info.phone}`,"","",0,"1.5 Miles"): ""}
       {agent_3_info.address1 !== false && agent_3_info.city !== false ? displayLocation(`${agent_3_info && agent_3_info.agency_name}`,``, `${agent_3_info && agent_3_info.address1}`, `${agent_3_info && agent_3_info.city}`, `${agent_3_info && agent_3_info.state}`, `${agent_3_info && agent_3_info.zip}`,`${agent_3_info && agent_3_info.phone}`,"","",0,"1.5 Miles"): ""}
      
       {/* {displayLocation(`${agent_2_info && agent_2_info.agency_name}`,"123 Main Street", "", "Miami", "FL", "33130","786-823-8346","","",0,"0.8 Miles")}
       {displayLocation(`${agent_3_info && agent_3_info.agency_name}`,"123 Main Street", "Suite 305", "Miami", "FL", "33165","305-980-3443","","",0,"2.3 Miles")}       */}
      
       
      </div> </>}
      
  </>
)

    };

    
    const displayLocation = (agency,addr1,addr2,city,state,zip,phone,lat,long,top,distance) =>{
      return (
              <>
                  <div className="our-mission-div locations-div">
                      <div className="row1"> 
                    {top === 1 ? <span className="top-recommended">
                        Recommended
                      </span> : <span className="top-empty">
                        
                      </span>} 
                          <h3 style={{marginTop:"-30px",lineHeight:"22px"}}><b>{agency}</b></h3> 
                          </div>
                      <div className="row2">
                          <p> {addr1}  {addr2 !=="" ? <><br></br> {addr2} <br></br></> : <br></br> }  {city.trim()}, {state} {zip}</p>  
                      </div>
                      {/* <div className="row3">
                        <span style={{textDecoration:"underline"}}>Get Directions</span><span style={{fontSize:""}}> ({distance}) </span>
                      </div> */}
                      {phone.length < 10 ? "":<>
                      <div className="row4" >
                      <a href={`tel:+${phone}`} style={{textDecoration:"none"}}>
                          <button className="faaCallButton">  ({phone.substring(0,3)}) {phone.substring(3,6)}-{phone.substring(6,10)}</button>
                      </a>
                          {/* <button className="faaCallButton">  Get Directions</button> */}
                      </div>
                      </>}
                     
                  </div>        
              </>
              )
    }

  return (
    <div style={{marginTop:"3em",display:""}}>

    
{/* {agentLocatorZipCodeReducer !== null && agent_1_info.agency_name !== undefined && !displayNoAgentsFound ? <center>
        <a href={`${buyNowURl}`}>
          <h3 className="blink_me " style={{color:"#a30000",fontWeight:"bold",marginBottom:"1em"}}>
              Start A Free Quote Now
          </h3>
        </a>
    </center>: "" }  */}

    {agentLocatorZipCodeReducer !== null && agent_1_info.agency_name !== undefined && !displayNoAgentsFound && !isLoading ? <center>
        <a href={`${buyNowURl}`}>
          <button className="blink_me padding home-page-card-button" style={{fontSize:"21px",backgroundColor:"#",width:"275px",color:"white",fontWeight:"bold",marginBottom:"1em",border:"0px"}}>
           Start A Free Quote Now
          </button>
        </a>
    </center>: "" }

      <div style={{ marginBottom: "2rem",display:"none" }} className="about-uaic-box">
        <i style={{ marginLeft: "2rem" }} className="fas fa-user-tie fa-2x"></i>
        <h2 style={{ marginLeft: "1rem" }}>Find An Agent Near You </h2>
             
      </div>
      {errorMessageComponent()}
      {messageComponent()}
      <div
      style={{paddingBottom:"25px"}}
        // className={isComponentLoaded ? "baa-banner faa-banner" : "baa-banner-not-rendered"}
        className="baa-banner faa-banner"
      >
        <div className="blue-color">
        
            <p className="baa">
            
            Find An Agent Near You
              {/* <p style={{ fontSize: "20px" }}>Get Started Today!</p> */}
            </p>
            <LazyLoadImage
              className="baa-img faa-img"
              alt="Group of people waving at the camera"
              src="/uaichome/pictures/gps_locator.png"
              effect="blur"
              
            />
          <div className="txt-input-div faa-input-div" style={{display:"flex",marginTop:"0px"}}>
          <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              target="_blank"
              autoComplete="false"
              rel="noreferrer"
            >
              <input
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                  
                    if (
                        agentzipCode.length < 5 
                    ) {
                      setSubmitRequest(false)
                      dispatch({
                        type: "SET_ERROR_MESSAGE",
                        payload: true,
                      });
                      dispatch({
                        type: "SET_MESSAGE",
                        payload: "Please enter a 5 digit zip code",
                      });
                      e.preventDefault();
                     
                    } else {
                      setSubmitRequest(true);
                      GA(
                        "FIND_AGENT_LOCATOR",
                        `ZIP: ${agentzipCode} - zip code entered`
                      );
                      window.location.hash = '/uaichome/FindAnAgent?zip='+ agentzipCode
                     
                     }
                 
                    if (agentzipCode === "" || agentzipCode.length < 5) {

                      setSubmitRequest(false);
                      dispatch({
                        type: "SET_ERROR_MESSAGE",
                        payload: true,
                      });
                      dispatch({
                        type: "SET_MESSAGE",
                        payload: "Please enter a 5 digit zip code",
                      });
                     
                    }else{
                      if(agentzipCode.length === 5){
                        setSubmitRequest(true)
                          setIsLoading(true);
                      }
                    }
                   
                  }
                }}
                onKeyPress={(e) => {
                  numbersOnly(e);
                }}
                type="tel"
                // autoComplete="off"
                id="zip-input-box-1"
                onChange={(e) => {
                  setAgentZipCode(e.target.value);
                }}
                // value={agentLocatorZipCodeReducer === null && agentzipCode.length >= 1  ? agentzipCode : agentLocatorZipCodeReducer }
                value={agentzipCode }
                maxLength="5"
                className="zip-input"
                placeholder={agentLocatorZipCodeReducer === null ? "Enter your zip code": agentLocatorZipCodeReducer}
                style={{border:"2px solid"}}
              ></input>

            </form>
            <Link  to={`/uaichome/FindAnAgent?zip=${agentzipCode}`} style={{textDecoration:"none"}}>
            <button
                  onClick={(e) => {
                    
                    // checkZipCodeValidationAgents();
                    if (agentzipCode.length !== 5) {
                      e.preventDefault();
                      setSubmitRequest(false)
                
                    } else {
                      setSubmitRequest(true)
                      isRequestAgentTimerRunning(true)
                      setIsLoading(true);
                      dispatch({
                        type: "SET_AGENT_LOCATOR_ZIP",
                        payload: agentzipCode,
                      });
                      GA(
                        "FIND_AGENT_LOCATOR",
                        `ZIP: ${agentzipCode} - zip code entered and clicked`
                      );
                    }
                  }}
                  className="findButton"
                  disabled={agentzipCode.length !== 5  ? "disabled" : ""}
                >
                  Search 
            </button>
            </Link>
          </div>
       
        </div>
       
      </div>
      
    

     

      {!isLoading ? (
    agentLocatorZipCodeReducer !== null && agent_1_info.agency_name !== undefined ? displayAgentInfo(): "" ) : (
      <div style={{marginTop:"2em"}}>
        <PuffLoader color={"blue"} css={override} size={50} />
      </div>
      )}


    </div>
  );
};

export default FindAnAgentComponent;

import "./App.css";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Switch, Route, Redirect } from "react-router-dom";
import "react-lazy-load-image-component/src/effects/blur.css";

//components
import Claims from "./components/claims/claims";
import ClaimsFaq from "./components/claims/claimsFaq";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import AboutUs from "./components/aboutUs/aboutUs";
import BecomeAgent from "./components/becomeAgent/becomeAgent";
import Careers from "./components/careers/careers";
import Contact from "./components/contact/contact";
import RequestedHelp from "./components/contact/requestedHelp";
import Insurance from "./components/insurance/insurance";
import LeinHolder from "./components/leinHolder/leinHolder";
import HomePage from "./components/homePage/homePage";
import HomePage2 from "./components/homePage/homePage2";
import NotFoundPage from "./components/404page/404page";
import Accessibility from "./components/footer/accessibility/accessibility";
import SiteRequirements from "./components/footer/siteRequirements/siteRequirements";
import SiteMap from "./components/footer/siteMap/siteMap";
import PrivacyPolicy from "./components/footer/privacyPolicy/privacyPolicy";
import NewUserCustomer from "./components/header/newUserCustomer";
import SEO_Header from "./SEO_Headers";
import GetAQuote from "./components/getAQuote/getAQuote";
import FindAnAgent from "./components/findAnAgent/findAnAgent";

function App() {
  const dispatch = useDispatch();

  const isMessageSuccess = useSelector((state) => state.messageSuccessReducer);
  const isMessageError = useSelector((state) => state.messageErrorReducer);
  const message = useSelector((state) => state.messageReducer);

  useEffect(() => {
    if (window.navigator.userAgent.indexOf("Trident") >= 0) {
      // window.location.href = "https://www.uaig.net/defaultIE.html";
    }
  }, []);

  useEffect(() => {
    if (isMessageError === true) {
      setTimeout(() => {
        dispatch({
          type: "SET_ERROR_MESSAGE",
          payload: false,
        });
      }, 4000);
    } else if (isMessageSuccess === true) {
      setTimeout(() => {
        dispatch({
          type: "SET_SECCESS_MESSAGE",
          payload: false,
        });
      }, 4000);
    }
  }, [isMessageSuccess, isMessageError]);

  const errorMessageComponent = () => {
    return (
      <div
        className={
          isMessageError
            ? "error-message-app-shown"
            : "error-message-app-hidden"
        }
      >
        <div></div>
        <h3 className="error-message-header">{message}</h3>
      </div>
    );
  };

  const messageComponent = () => {
    return (
      <div
        className={
          isMessageSuccess ? "message-app-shown" : "message-app-hidden"
        }
      >
        <div></div>
        <h3>{message}</h3>
      </div>
    );
  };

  return (
    <div className="App">
      {errorMessageComponent()}
      {messageComponent()}
      <header>
        <Header />
      </header>
      <main>
        <>
          <SEO_Header />
          <Switch>
            <Redirect
              exact
              from="/"
              to="/uaichome"
              component={HomePage}
            ></Redirect>
            <Route exact path="/uaichome" component={HomePage2} />
            <Route exact path="/uaichome/old" component={HomePage} />
            <Route exact path="/uaichome/aboutus" component={AboutUs} />
            <Route exact path="/uaichome/becomeagent" component={BecomeAgent} />
            <Route exact path="/uaichome/claims" component={Claims} />
            <Route exact path="/uaichome/claims/faq" component={ClaimsFaq} />
            <Route exact path="/uaichome/careers" component={Careers} />
            <Route exact path="/uaichome/contact" component={Contact} />
            <Route exact path="/uaichome/FindAnAgent" component={FindAnAgent} />
            <Route
              exact
              path="/uaichome/requestedhelp"
              component={RequestedHelp}
            />
            <Route exact path="/uaichome/insurance" component={Insurance} />
            <Route exact path="/uaichome/leinholder" component={LeinHolder} />
            <Route exact path="/uaichome/site-map" component={SiteMap} />
            <Route
              exact
              path="/uaichome/site-requirements"
              component={SiteRequirements}
            />
            <Route
              exact
              path="/uaichome/accessibility"
              component={Accessibility}
            />
            <Route
              exact
              path="/uaichome/privacy-policy"
              component={PrivacyPolicy}
            />
            <Route
              exact
              path="/uaichome/newuser/customer"
              component={NewUserCustomer}
            />
            <Route exact path="/uaichome/getaquote" component={GetAQuote} />
            <Route render={() => <Redirect to="/404" />}>
              <NotFoundPage />
            </Route>
          </Switch>
        </>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;

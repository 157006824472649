import React, { useState, useEffect } from "react";
import "./careersClaim.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const CareersClaim = () => {
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);

  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  return (
    <div
      className={
        isComponentLoaded ? "CareersClaim" : "CareersClaim-not-rendered"
      }
    >
      <div className="header-picture">
        <LazyLoadImage
          style={{ width: "100%" }}
          src="uaichome/pictures/claims.png"
          effect="blur"
          alt="There is a person holding a notebook, next wrecked car taking notes"
        ></LazyLoadImage>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <h2 className="blue-color">Claims Adjuster</h2>
        <hr></hr>
        <div>
          <p>
            Claims adjuster is a term used to describe someone who evaluates the
            damage caused to property or people when an insurance related
            accident occurs.
          </p>
          <br></br>
          <p>
            An adjuster will frequently verify that coverage applies through an
            insurance policy, investigate liability for the damages caused, and
            make restitution to the injured person based on their physical,
            emotional, or physical property damages.
          </p>
          <br></br>
        </div>
      </div>
    </div>
  );
};

export default CareersClaim;

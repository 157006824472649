import React, { useEffect, useState } from "react";
import "./careers.css";

import CareersFirstPage from "./careersComponents/careersFirstPage/careersFirstPage";
import Accounting from "./careersComponents/accounting/accounting";
import Diversity from "./careersComponents/diversity/diversity";
import CareersFAQ from "./careersComponents/FAQ/careersFAQ";
import Benefits from "./careersComponents/benefits/benefits";
import CareersClaims from "./careersComponents/claims/careersClaims";
import Corporate from "./careersComponents/corporate/corporate";
import It from "./careersComponents/informationAndTechnology/it";
import Underwriting from "./careersComponents/Underwriting/underwriting";
import JobFairs from "./careersComponents/jobFairs/jobFairs";
import { useDispatch, useSelector } from "react-redux";

const Career = () => {
  const dispatch = useDispatch();

  const selectedCareersTabReducer = useSelector(
    (state) => state.selectedCareersTab
  );

  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  const [windowWidth, setWindowWidth] = useState();
  const [windowScrollPosition, setWindowScrollPosition] = useState(0);
  const [isMobileNavigationOpen, setIsMobileNavigationOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
    window.addEventListener("scroll", () =>
      setWindowScrollPosition(window.scrollY)
    );
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const href = window.location.href;
    const params = href.split("?")[1];

    // Be sure url params exist
    if (params && params !== "") {
      const result = params.split("&").reduce(function (res, item) {
        const parts = item.split("=");
        res[parts[0]] = parts[1];
        return res;
      }, {});

      // the url should look like this
      // uaichome/careers?careers=benefits

      if (result.careers === "careers") {
        dispatch({
          type: "SET_SELECTED_CAREERS_TAB",
          payload: "Careers",
        });
      } else if (result.careers === "benefits") {
        dispatch({
          type: "SET_SELECTED_CAREERS_TAB",
          payload: "Benefits",
        });
      } else if (result.careers === "diversity") {
        dispatch({
          type: "SET_SELECTED_CAREERS_TAB",
          payload: "Diversity",
        });
      } else if (result.careers === "faq") {
        dispatch({
          type: "SET_SELECTED_CAREERS_TAB",
          payload: "FAQ",
        });
      } else if (result.careers === "jobfairs") {
        dispatch({
          type: "SET_SELECTED_CAREERS_TAB",
          payload: "Job_Fairs",
        });
      } else if (result.careers === "accounting") {
        dispatch({
          type: "SET_SELECTED_CAREERS_TAB",
          payload: "Accounting",
        });
      } else if (result.careers === "claims") {
        dispatch({
          type: "SET_SELECTED_CAREERS_TAB",
          payload: "Claims",
        });
      } else if (result.careers === "corporate") {
        dispatch({
          type: "SET_SELECTED_CAREERS_TAB",
          payload: "Corporate_&_Executive",
        });
      } else if (result.careers === "information") {
        dispatch({
          type: "SET_SELECTED_CAREERS_TAB",
          payload: "Information_Technology",
        });
      } else if (result.careers === "underwriting") {
        dispatch({
          type: "SET_SELECTED_CAREERS_TAB",
          payload: "Underwriting",
        });
      }
    }
  }, []);

  //load animation
  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  const careersSideNavigation = () => {
    return (
      <div className="careers-navigation">
        <div className="careers-navigation-inner-div">
          <div>
            <h4>What UAIC Offers</h4>
            <hr></hr>
            <div
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_CAREERS_TAB",
                  payload: "Careers",
                });
                window.scrollTo({
                  top: 100,
                  behavior: "smooth",
                });
              }}
              style={{ marginLeft: "1rem" }}
            >
              <p
                className="careers-navigation-link-item"
                style={
                  selectedCareersTabReducer === "Careers"
                    ? {
                        fontWeight: "bolder",
                        color: "rgb(50, 50, 150)",
                      }
                    : {}
                }
              >
                Careers
              </p>
            </div>
            <div
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_CAREERS_TAB",
                  payload: "Benefits",
                });
                window.scrollTo({
                  top: 100,
                  behavior: "smooth",
                });
              }}
              style={{ marginLeft: "1rem" }}
            >
              <p
                className="careers-navigation-link-item"
                style={
                  selectedCareersTabReducer === "Benefits"
                    ? {
                        fontWeight: "bolder",
                        color: "rgb(50, 50, 150)",
                      }
                    : {}
                }
              >
                Benefits
              </p>
            </div>
            <div
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_CAREERS_TAB",
                  payload: "Diversity",
                });
                window.scrollTo({
                  top: 100,
                  behavior: "smooth",
                });
              }}
              style={{ marginLeft: "1rem" }}
            >
              <p
                className="careers-navigation-link-item"
                style={
                  selectedCareersTabReducer === "Diversity"
                    ? {
                        fontWeight: "bolder",
                        color: "rgb(50, 50, 150)",
                      }
                    : {}
                }
              >
                Diversity
              </p>
            </div>
            <div
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_CAREERS_TAB",
                  payload: "FAQ",
                });
                window.scrollTo({
                  top: 100,
                  behavior: "smooth",
                });
              }}
              style={{ marginLeft: "1rem" }}
            >
              <p
                className="careers-navigation-link-item"
                style={
                  selectedCareersTabReducer === "FAQ"
                    ? {
                        fontWeight: "bolder",
                        color: "rgb(50, 50, 150)",
                      }
                    : {}
                }
              >
                FAQ
              </p>
            </div>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <h4>Recruiting Events</h4>
            <hr></hr>
            <div
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_CAREERS_TAB",
                  payload: "Job_Fairs",
                });
                window.scrollTo({
                  top: 100,
                  behavior: "smooth",
                });
              }}
              style={{ marginLeft: "1rem" }}
            >
              <p
                className="careers-navigation-link-item"
                style={
                  selectedCareersTabReducer === "Job_Fairs"
                    ? {
                        fontWeight: "bolder",
                        color: "rgb(50, 50, 150)",
                      }
                    : {}
                }
              >
                Job Fairs
              </p>
            </div>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <h4>Career Paths</h4>
            <hr></hr>
            <div
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_CAREERS_TAB",
                  payload: "Accounting",
                });
                window.scrollTo({
                  top: 100,
                  behavior: "smooth",
                });
              }}
              style={{ marginLeft: "1rem" }}
            >
              <p
                className="careers-navigation-link-item"
                style={
                  selectedCareersTabReducer === "Accounting"
                    ? {
                        fontWeight: "bolder",
                        color: "rgb(50, 50, 150)",
                      }
                    : {}
                }
              >
                Accounting
              </p>
            </div>
            <div
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_CAREERS_TAB",
                  payload: "Claims",
                });
                window.scrollTo({
                  top: 100,
                  behavior: "smooth",
                });
              }}
              style={{ marginLeft: "1rem" }}
            >
              <p
                className="careers-navigation-link-item"
                style={
                  selectedCareersTabReducer === "Claims"
                    ? {
                        fontWeight: "bolder",
                        color: "rgb(50, 50, 150)",
                      }
                    : {}
                }
              >
                Claims
              </p>
            </div>
            <div
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_CAREERS_TAB",
                  payload: "Corporate_&_Executive",
                });
                window.scrollTo({
                  top: 100,
                  behavior: "smooth",
                });
              }}
              style={{ marginLeft: "1rem" }}
            >
              <p
                className="careers-navigation-link-item"
                style={
                  selectedCareersTabReducer === "Corporate_&_Executive"
                    ? {
                        fontWeight: "bolder",
                        color: "rgb(50, 50, 150)",
                      }
                    : {}
                }
              >
                Corporate & Executive
              </p>
            </div>
            <div
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_CAREERS_TAB",
                  payload: "Information_Technology",
                });
                window.scrollTo({
                  top: 100,
                  behavior: "smooth",
                });
              }}
              style={{ marginLeft: "1rem" }}
            >
              <p
                className="careers-navigation-link-item"
                style={
                  selectedCareersTabReducer === "Information_Technology"
                    ? {
                        fontWeight: "bolder",
                        color: "rgb(50, 50, 150)",
                      }
                    : {}
                }
              >
                Information Technology
              </p>
            </div>
            <div
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_CAREERS_TAB",
                  payload: "Underwriting",
                });
                window.scrollTo({
                  top: 100,
                  behavior: "smooth",
                });
              }}
              style={{ marginLeft: "1rem" }}
            >
              <p
                className="careers-navigation-link-item"
                style={
                  selectedCareersTabReducer === "Underwriting"
                    ? {
                        fontWeight: "bolder",
                        color: "rgb(50, 50, 150)",
                      }
                    : {}
                }
              >
                Underwriting
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const careersNavigationMobile = () => {
    return (
      <div>
        <div
          style={
            windowScrollPosition >= 80
              ? { position: "fixed", top: "100px" }
              : {}
          }
          className={
            isMobileNavigationOpen
              ? "navigation-mobile-open"
              : "navigation-mobile-closed"
          }
        >
          <div
            onClick={() => {
              setIsMobileNavigationOpen(!isMobileNavigationOpen);
            }}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>What UAIC Offers</h3>
            {isMobileNavigationOpen ? (
              <i
                style={{ marginLeft: "10px" }}
                className="fas fa-chevron-up"
              ></i>
            ) : (
              <i
                style={{ marginLeft: "10px" }}
                className="fas fa-chevron-down"
              ></i>
            )}
          </div>
          <br></br>
          <div>
            <div
              onClick={() => {
                setIsMobileNavigationOpen(false);

                dispatch({
                  type: "SET_SELECTED_CAREERS_TAB",
                  payload: "Careers",
                });
                window.scrollTo({
                  top: 100,
                  behavior: "smooth",
                });
              }}
              style={{ marginLeft: "1rem" }}
            >
              <p
                className="careers-navigation-link-item"
                style={
                  selectedCareersTabReducer === "Careers"
                    ? {
                        fontWeight: "bolder",
                        color: "rgb(50, 50, 150)",
                      }
                    : {}
                }
              >
                Careers
              </p>
            </div>
            <div
              onClick={() => {
                setIsMobileNavigationOpen(false);
                dispatch({
                  type: "SET_SELECTED_CAREERS_TAB",
                  payload: "Benefits",
                });
                window.scrollTo({
                  top: 100,
                  behavior: "smooth",
                });
              }}
              style={{ marginLeft: "1rem" }}
            >
              <p
                className="careers-navigation-link-item"
                style={
                  selectedCareersTabReducer === "Benefits"
                    ? {
                        fontWeight: "bolder",
                        color: "rgb(50, 50, 150)",
                      }
                    : {}
                }
              >
                Benefits
              </p>
            </div>
            <div
              onClick={() => {
                setIsMobileNavigationOpen(false);

                dispatch({
                  type: "SET_SELECTED_CAREERS_TAB",
                  payload: "Diversity",
                });
                window.scrollTo({
                  top: 100,
                  behavior: "smooth",
                });
              }}
              style={{ marginLeft: "1rem" }}
            >
              <p
                className="careers-navigation-link-item"
                style={
                  selectedCareersTabReducer === "Diversity"
                    ? {
                        fontWeight: "bolder",
                        color: "rgb(50, 50, 150)",
                      }
                    : {}
                }
              >
                Diversity
              </p>
            </div>
            <div
              onClick={() => {
                setIsMobileNavigationOpen(false);

                dispatch({
                  type: "SET_SELECTED_CAREERS_TAB",
                  payload: "FAQ",
                });
                window.scrollTo({
                  top: 100,
                  behavior: "smooth",
                });
              }}
              style={{ marginLeft: "1rem" }}
            >
              <p
                className="careers-navigation-link-item"
                style={
                  selectedCareersTabReducer === "FAQ"
                    ? {
                        fontWeight: "bolder",
                        color: "rgb(50, 50, 150)",
                      }
                    : {}
                }
              >
                FAQ
              </p>
            </div>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <h4>Recruiting Events</h4>
            <hr></hr>
            <div
              onClick={() => {
                setIsMobileNavigationOpen(false);

                dispatch({
                  type: "SET_SELECTED_CAREERS_TAB",
                  payload: "Job_Fairs",
                });
                window.scrollTo({
                  top: 100,
                  behavior: "smooth",
                });
              }}
              style={{ marginLeft: "1rem" }}
            >
              <p
                className="careers-navigation-link-item"
                style={
                  selectedCareersTabReducer === "Job_Fairs"
                    ? {
                        fontWeight: "bolder",
                        color: "rgb(50, 50, 150)",
                      }
                    : {}
                }
              >
                Job Fairs
              </p>
            </div>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <h4>Career Paths</h4>
            <hr></hr>
            <div
              onClick={() => {
                setIsMobileNavigationOpen(false);

                dispatch({
                  type: "SET_SELECTED_CAREERS_TAB",
                  payload: "Accounting",
                });
                window.scrollTo({
                  top: 100,
                  behavior: "smooth",
                });
              }}
              style={{ marginLeft: "1rem" }}
            >
              <p
                className="careers-navigation-link-item"
                style={
                  selectedCareersTabReducer === "Accounting"
                    ? {
                        fontWeight: "bolder",
                        color: "rgb(50, 50, 150)",
                      }
                    : {}
                }
              >
                Accounting
              </p>
            </div>
            <div
              onClick={() => {
                setIsMobileNavigationOpen(false);

                dispatch({
                  type: "SET_SELECTED_CAREERS_TAB",
                  payload: "Claims",
                });
                window.scrollTo({
                  top: 100,
                  behavior: "smooth",
                });
              }}
              style={{ marginLeft: "1rem" }}
            >
              <p
                className="careers-navigation-link-item"
                style={
                  selectedCareersTabReducer === "Claims"
                    ? {
                        fontWeight: "bolder",
                        color: "rgb(50, 50, 150)",
                      }
                    : {}
                }
              >
                Claims
              </p>
            </div>
            <div
              onClick={() => {
                setIsMobileNavigationOpen(false);

                dispatch({
                  type: "SET_SELECTED_CAREERS_TAB",
                  payload: "Corporate_&_Executive",
                });
                window.scrollTo({
                  top: 100,
                  behavior: "smooth",
                });
              }}
              style={{ marginLeft: "1rem" }}
            >
              <p
                className="careers-navigation-link-item"
                style={
                  selectedCareersTabReducer === "Corporate_&_Executive"
                    ? {
                        fontWeight: "bolder",
                        color: "rgb(50, 50, 150)",
                      }
                    : {}
                }
              >
                Corporate & Executive
              </p>
            </div>
            <div
              onClick={() => {
                setIsMobileNavigationOpen(false);

                dispatch({
                  type: "SET_SELECTED_CAREERS_TAB",
                  payload: "Information_Technology",
                });
                window.scrollTo({
                  top: 100,
                  behavior: "smooth",
                });
              }}
              style={{ marginLeft: "1rem" }}
            >
              <p
                className="careers-navigation-link-item"
                style={
                  selectedCareersTabReducer === "Information_Technology"
                    ? {
                        fontWeight: "bolder",
                        color: "rgb(50, 50, 150)",
                      }
                    : {}
                }
              >
                Information Technology
              </p>
            </div>
            <div
              onClick={() => {
                setIsMobileNavigationOpen(false);

                dispatch({
                  type: "SET_SELECTED_CAREERS_TAB",
                  payload: "Underwriting",
                });
                window.scrollTo({
                  top: 100,
                  behavior: "smooth",
                });
              }}
              style={{ marginLeft: "1rem" }}
            >
              <p
                className="careers-navigation-link-item"
                style={
                  selectedCareersTabReducer === "Underwriting"
                    ? {
                        fontWeight: "bolder",
                        color: "rgb(50, 50, 150)",
                      }
                    : {}
                }
              >
                Underwriting
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSelectedComponent = () => {
    if (selectedCareersTabReducer === "Careers") {
      return <CareersFirstPage />;
    } else if (selectedCareersTabReducer === "Diversity") {
      return <Diversity />;
    } else if (selectedCareersTabReducer === "Benefits") {
      return <Benefits />;
    } else if (selectedCareersTabReducer === "FAQ") {
      return <CareersFAQ />;
    } else if (selectedCareersTabReducer === "Job_Fairs") {
      return <JobFairs />;
    } else if (selectedCareersTabReducer === "Accounting") {
      return <Accounting />;
    } else if (selectedCareersTabReducer === "Claims") {
      return <CareersClaims />;
    } else if (selectedCareersTabReducer === "Corporate_&_Executive") {
      return <Corporate />;
    } else if (selectedCareersTabReducer === "Information_Technology") {
      return <It />;
    } else if (selectedCareersTabReducer === "Underwriting") {
      return <Underwriting />;
    }
  };

  return (
    <div className={isComponentLoaded ? "careers" : "careers-not-rendered"}>
      <div className="career-uaic-box">
        <i style={{ marginLeft: "2rem" }} className="far fa-building fa-2x"></i>
        <h2 style={{ marginLeft: "1rem" }}> Careers </h2>
      </div>
      <div className="careers-div">
        {windowWidth >= 600 && careersSideNavigation()}
        {windowWidth <= 599 && careersNavigationMobile()}

        <div className="component-to-display-div">
          {renderSelectedComponent()}
        </div>
      </div>
    </div>
  );
};

export default Career;

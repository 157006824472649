import React, { useState, useEffect } from "react";
import "./benefits.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import GA from "../../../functions/GA";
const Benefits = () => {
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);

  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);
  return (
    <div className={isComponentLoaded ? "Benefits" : "Benefits-not-rendered"}>
      <div className="header-picture">
        <LazyLoadImage
          style={{ width: "100%" }}
          src="/uaichome/pictures/benefits2.png"
          alt="Word Benefits beeing written with a white marker"
          effect="blur"
        ></LazyLoadImage>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <h2 className="blue-color">Benefits</h2>
        <hr></hr>
        <div>
          <p>
            As you'd expect, we offer standard benefits including health care,
            dental and vision. But at UAIC, benefits go far beyond standard.
            UAIC wants its associates to be enriched both in and out of the
            office. We're pleased we can offer a highly competitive and
            comprehensive benefits package to our employees.
          </p>
          <br></br>
          <p>
            Our benefits package is designed to help our associates stay
            healthy, meet their long-term financial goals, as well as balance
            the demands of work and personal life. UAIC rewards hard work,
            dedication, and creativity with competitive salaries and a generous
            bonus system.
          </p>
          <a
            onClick={() => {
              GA(
                "Employment_Opportunities",
                "User clicked on the Employment_Opportunities on Careers Page"
              );
            }}
            target="_blank"
            rel="noreferrer"
            href="https://uaig.applytojob.com/apply"
          >
            {" "}
            <strong>
              View and apply for current employment opportunities at UAIC.
            </strong>
          </a>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <h4>
            The information below provides a list of our employee benefits:
          </h4>
          <ul>
            <li> Group Health Insurance (Medical, Dental, Rx)</li>

            <li>Vision Plan</li>

            <li>Flexible Spending Accounts (FSA)</li>

            <li>
              401K savings plan (Employer match 50% up to 8% of employee
              compensation)
            </li>

            <li>Short Term Disability</li>

            <li>Long Term Disability Insurance</li>

            <li>Life, Accidental Death & Dismemberment Insurance</li>

            <li>Voluntary Life Insurance</li>

            <li>Paid Time Off (PTO) + 1 Floating Holiday</li>

            <li>7 Paid Holidays per year</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Benefits;

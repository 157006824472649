import React, { useEffect, useState } from "react";
import GA from "../functions/GA";
import selectCorrectUrls from "../../selectCorrectUrls";
import { useDispatch, useSelector } from "react-redux";
import "./getAQuote.css";
import FirstPage from "../homePage/firstPage";
import { Link } from "react-router-dom";

import TextField from "@material-ui/core/TextField";

const GetAQuote = () => {
  const dispatch = useDispatch();

  const campaign = useSelector((state) => state.campaignReducer);
  const [zipCode, setZipCode] = useState("");
  const [isContinuePreviousQuote, setIsContinuePreviousQuote] = useState(false);
  const [isGetAQuoteOpen, setIsGetAQuoteOpen] = useState(false);
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);

  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  const selectCorrectHrefForTheZip = (e) => {
    const firstTwo = zipCode.substring(0, 2);

    if (
      firstTwo === "73" ||
      firstTwo === "75" ||
      firstTwo === "76" ||
      firstTwo === "77" ||
      firstTwo === "78" ||
      firstTwo === "88" ||
      firstTwo === "79"
    ) {
      return `${
        selectCorrectUrls().quotePortal
      }/uaicins/txsale/quick/start/UAIS`;
    } else if (firstTwo === "32" || firstTwo === "33" || firstTwo === "34") {
      return `${
        selectCorrectUrls().quotePortal
      }/uaicins/sale/quick/index/UAI?campaign=${campaign}`;
    } else {
      return "javascript:void(0)";
    }
  };

  function numbersOnly(e) {
    var key = e.which || e.keyCode; //use event.which if it's truthy, and default to keyCode otherwise
    // Allow: backspace, delete, tab, and enter
    var controlKeys = [8, 9, 13];
    //for mozilla these are arrow keys
    //if ($.browser.mozilla) controlKeys = controlKeys.concat([37, 38, 39, 40]);

    // Ctrl+ anything or one of the conttrolKeys is valid
    var isControlKey =
      e.ctrlKey || controlKeys.join(",").match(new RegExp(key));
    if (isControlKey) {
      return;
    }
    // stop current key press if it's not a number
    if (!(48 <= key && key <= 57)) {
      e.preventDefault();
      return;
    }
  }

  const scrollToTop = (paddingTop) => {
    window.scrollTo({
      top: paddingTop,
      behavior: "smooth",
    });
  };

  const getCurrentYear = () => {
    const date = new Date();

    return date.getFullYear();
  };

  const continuePreviousQuoteComponent = () => {
    return (
      <div>
        <div
          onClick={() => {
            setIsContinuePreviousQuote(false);
            setIsGetAQuoteOpen(false);
          }}
          className={
            isContinuePreviousQuote || isGetAQuoteOpen
              ? "continue-previous-quote-dark-background-on"
              : "continue-previous-quote-dark-background-off"
          }
          style={{ bottom: "305px" }}
        ></div>
        <div
          className={
            isContinuePreviousQuote
              ? "continue-previous-quote-component-shown get-a-quote-previous-quote-shown"
              : "continue-previous-quote-component-hidden"
          }
        >
          <div
            style={{
              position: "sticky",
              top: "0",
              backgroundColor: "white",
              paddingTop: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                paddingLeft: "1rem",
              }}
            >
              <h3>Continue your previous quote </h3>
              <h2
                className="close-login-menu-icon"
                onClick={() => {
                  setIsContinuePreviousQuote(false);
                }}
                style={{ right: "0px" }}
              >
                <i
                  className="fa fa-times-circle"
                  style={{
                    fontSize: "1.5em",
                    color: "#01224f",
                  }}
                  aria-hidden="true"
                  aria-labelledby="Close Menu"
                ></i>
              </h2>
            </div>
            <hr></hr>
          </div>

          <div>
            <FirstPage />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={
        isComponentLoaded
          ? "get-a-quote-route-component"
          : "get-a-quote-route-component off"
      }
    >
      <div className="get-a-quote-route-header">
        <h1 style={{ color: "white" }}>Get a quote now!</h1>
      </div>
      {continuePreviousQuoteComponent()}
      <div
        style={{
          display: "flex",
          width: "70%",
          justifyContent: "space-between",
          margin: "auto",
          textAlign: "center",
        }}
      >
        <div className="get-a-quote-input-div">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            target="_blank"
            autoComplete="false"
          >
            <TextField
              label="Zip Code"
              variant="outlined"
              style={{ width: "100%" }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  if (selectCorrectHrefForTheZip() === "javascript:void(0)") {
                    GA(
                      "Start_Quote_FAILED",
                      `ZIP: ${zipCode} - zip code entered and rejected`
                    );

                    e.preventDefault();
                  } else {
                    GA(
                      "Start_Quote_SUCCESSFUL",
                      `ZIP: ${zipCode} - clicked on Start Quote button`
                    );

                    window.open(selectCorrectHrefForTheZip());
                  }

                  if (zipCode === "") {
                    dispatch({
                      type: "SET_ERROR_MESSAGE",
                      payload: true,
                    });
                    dispatch({
                      type: "SET_MESSAGE",
                      payload: "Please enter a zip code",
                    });
                  } else if (
                    selectCorrectHrefForTheZip() === "javascript:void(0)"
                  ) {
                    dispatch({
                      type: "SET_ERROR_MESSAGE",
                      payload: true,
                    });
                    dispatch({
                      type: "SET_MESSAGE",
                      payload:
                        "Buy online currently not available in this zip code",
                    });
                    GA(
                      "Start_Quote_FAILED",
                      `ZIP: ${zipCode} - zip code entered and rejected`
                    );
                  }
                }
              }}
              onKeyPress={(e) => {
                numbersOnly(e);
              }}
              type="tel"
              // autoComplete="off"
              id="zip-input-box-3"
              onChange={(e) => {
                setZipCode(e.target.value);
              }}
              maxLength="5"
              className="zip-input"
              placeholder="Enter zip code"
            ></TextField>
          </form>
          <div
            className="get-a-quote-button-wrapper"
            onClick={() => {
              GA("Start_Quote", "User clicked on Start Quote button");

              if (zipCode === "") {
                dispatch({
                  type: "SET_ERROR_MESSAGE",
                  payload: true,
                });
                dispatch({
                  type: "SET_MESSAGE",
                  payload: "Please enter a zip code",
                });
              } else if (
                selectCorrectHrefForTheZip() === "javascript:void(0)"
              ) {
                dispatch({
                  type: "SET_ERROR_MESSAGE",
                  payload: true,
                });
                dispatch({
                  type: "SET_MESSAGE",
                  payload:
                    "Buy online currently not available in this zip code",
                });
                GA(
                  "Start_Quote_FAILED",
                  `ZIP: ${zipCode} - zip code entered and rejected`
                );
              }
            }}
          >
            <p>
              <button
                style={{ width: "100%" }}
                onClick={(e) => {
                  if (selectCorrectHrefForTheZip() === "javascript:void(0)") {
                    e.preventDefault();
                  } else {
                    window.open(selectCorrectHrefForTheZip());
                  }
                }}
                className="home-page-card-button"
              >
                <i
                  style={{ marginRight: "10px" }}
                  className="fas fa-hand-holding-usd"
                ></i>
                Start Quote{" "}
              </button>
            </p>
          </div>
          <p
            style={{
              fontSize: "15px",
              marginTop: "5px",
              cursor: "pointer",
              textAlign: "center",
            }}
          >
            <p
              style={{ textDecoration: "none", color: "black" }}
              onClick={() => {
                setIsContinuePreviousQuote(true);
                GA("Previous_Quote", "User clicked on Continue previous quote");
              }}
            >
              Continue Previous Quote
            </p>
          </p>
        </div>
      </div>
      <div className="get-a-quote-footer">
        <ul>
          <li>
            <Link
              style={{
                textDecoration: "none",
              }}
              to="/uaichome/site-map"
              onClick={() => {
                scrollToTop(0);
              }}
            >
              <p className="footer-links">Site Map</p>
            </Link>
          </li>
          <li>
            <Link
              style={{
                textDecoration: "none",
              }}
              to="/uaichome/site-requirements"
              onClick={() => {
                scrollToTop(0);
              }}
            >
              <p className="footer-links">Site Requirements</p>
            </Link>
          </li>
          <li>
            <Link
              style={{
                textDecoration: "none",
              }}
              to="/uaichome/accessibility"
              onClick={() => {
                scrollToTop(0);
              }}
            >
              <p className="footer-links">Accessibility Statement</p>
            </Link>
          </li>
          <li>
            <Link
              style={{
                textDecoration: "none",
              }}
              to="/uaichome/privacy-policy"
              onClick={() => {
                scrollToTop(0);
              }}
            >
              <p className="footer-links">Privacy Policy</p>
            </Link>
          </li>
          <li>
            <Link
              style={{
                textDecoration: "none",
              }}
              to="/uaichome/contact"
              onClick={() => {
                scrollToTop(0);
              }}
            >
              <p className="footer-links">Contact Us</p>
            </Link>
          </li>
        </ul>
        <br></br>
      </div>
      <div className="get-a-quote-copy-rigth">
        <span>
          Copyright © {getCurrentYear()} United Automobile Insurance Company
        </span>
      </div>
    </div>
  );
};

export default GetAQuote;

import Axios from "axios";
import React, { useState, useEffect } from "react";
import { isOpera } from "react-device-detect";
import { useDispatch } from "react-redux";
import selectCorrectUrls from "../../selectCorrectUrls";
import "./firstPage.css";

const FirstPage = (props) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("");

  const [isDisabled, setIsDisabled] = useState(false);

  const [licenseNumber, setLicenseNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [isPhoneNumberOnFocus, setIsPhoneNumberOnFocus] = useState(false);
  const [isLicenseNumberOnFocus, setIsLicenseNumberOnFocus] = useState(false);
  const [OTP, setOTP] = useState("");
  const [requestOTP, setRequestOTP] = useState("x");
  const [clientId, setClientId] = useState("");
  const [isConfirmOTPMenuOpen, setIsConfirmOTPMenuOpen] = useState(false);
  const [hideOTPContainer, setIshideOTPContainer] = useState(false);
  
  

  const [otpTimerSeconds, setOtpTimerSeconds] = useState(180);
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  const onChangeInput = (e) => {
    const id = e.target.id;

    if (id === "license_no") {
      setSelected(() => "license");
      setLicenseNumber(() => e.target.value);
    } else if (id === "driverphone") {
      setSelected(() => "phone");
      setPhoneNumber(() => e.target.value);
    }
  };

  useEffect(() => {
    if (props.isOpened === false) {
      setPhoneNumber("");
      setLicenseNumber("");
    }
  }, [props]);

  useEffect(() => {
    if (selected === "phone") {
      setLicenseNumber("");
    } else if (selected === "license") {
      setPhoneNumber("");
    }

    if (licenseNumber.length >= 5) {
      setIsDisabled(false);
    } else if (phoneNumber.length >= 10) {
      setIsDisabled(false);
    } else setIsDisabled(true);
  }, [licenseNumber, phoneNumber]);

  useEffect(() => {
    if (isTimerRunning === true) {
      let countDown = otpTimerSeconds;
      let interval = setInterval(() => {
        countDown--;
        setOtpTimerSeconds((otpTimerSeconds) => otpTimerSeconds - 1);

        if (countDown <= 0) {
          clearInterval(interval);
          setIsTimerRunning(false);
        }
      }, 1000);
    }
  }, [isTimerRunning]);

  const formatPhoneNumber = (phoneNumberString) => {
    let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + "***" + "-" + match[3];
    }
    return "";
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (licenseNumber !== "") {
      Axios.post(
        `${
          selectCorrectUrls().insuredPortal
        }/uaicins/agent/zip/licsearch?licnum=${licenseNumber}`
      )
        .then((response) => {
          if (response.data.status === "0") {
            dispatch({
              type: "SET_ERROR_MESSAGE",
              payload: true,
            });
            dispatch({
              type: "SET_MESSAGE",
              payload: response.data.errorMsg,
            });
          } else if (response.data.status === "1") {
            dispatch({
              type: "SET_SECCESS_MESSAGE",
              payload: true,
            });
            dispatch({
              type: "SET_MESSAGE",
              payload:
                response.data.phone === null
                  ? response.data.msg
                  : response.data.msg.slice(0, -1) +
                    " to " +
                    formatPhoneNumber(response.data.phone),
            });

            setRequestOTP(response.data.OTP);
            setIsConfirmOTPMenuOpen(true);
            setOtpTimerSeconds(180);
            setIsTimerRunning(true);
            setClientId(response.data.CLID);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      Axios.post(
        `${
          selectCorrectUrls().insuredPortal
        }/uaicins/agent/zip/phonesearch?phone=${phoneNumber}`
      )
        .then((response) => {
          if (response.data.status === "0") {
            dispatch({
              type: "SET_ERROR_MESSAGE",
              payload: true,
            });
            dispatch({
              type: "SET_MESSAGE",
              payload: response.data.errorMsg,
            });
          } else if (response.data.status === "1") {
            setRequestOTP(response.data.OTP);
            dispatch({
              type: "SET_SECCESS_MESSAGE",
              payload: true,
            });
            dispatch({
              type: "SET_MESSAGE",
              payload: response.data.msg,
            });
            setRequestOTP(response.data.OTP);
            setIsConfirmOTPMenuOpen(true);
            setOtpTimerSeconds(180);
            setIsTimerRunning(true);
            setClientId(response.data.CLID);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const verifyOTP = () => {
    if (OTP === requestOTP.trim()) {
      Axios.post(
        `${
          selectCorrectUrls().quotePortal
        }/uaicins/agent/zip/validateopt?clid=${clientId}&otp=${OTP}`
      )
        .then((response) => {
          if (response.data.status === "0") {
            dispatch({
              type: "SET_ERROR_MESSAGE",
              payload: true,
            });
            dispatch({
              type: "SET_MESSAGE",
              payload: response.data.errorMsg,
            });
          } else {
            window.open(response.data.returnUrl);
            setOTP("");
            setLicenseNumber("");
            setPhoneNumber("");
            setIsConfirmOTPMenuOpen(false);

          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        payload: true,
      });
      dispatch({
        type: "SET_MESSAGE",
        payload: "Code entered did not match",
      });
    }
  };

  const confirmOTPComponent = () => {
    return (
      <div>
        <div
          className={
            isConfirmOTPMenuOpen
              ? "continue-previous-quote-dark-background-on"
              : "continue-previous-quote-dark-background-off"
          }
        ></div>
        <div
          className={
            isConfirmOTPMenuOpen || hideOTPContainer
              ? "otp-component-shown"
              : "otp-component-hidden"
          }
        >
          <h2
            style={{ top: 15 }}
            className="close-login-menu-icon"
            onClick={() => {
              setOTP("");
              setLicenseNumber("");
              setPhoneNumber("");
              setIsConfirmOTPMenuOpen(false);
            }}
            aria-label="Close"
          >
            <i
              className="fa fa-times-circle"
              style={{
                fontSize: "1.5em",
                color: "#01224f",
              }}
            ></i>
          </h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            autoComplete="false"
          >
            <p>Enter the Pin</p>

            <label for="otp-input-box"></label>
            <input
              value={OTP}
              type="tel"
              name="otp-input-box"
              autoComplete="false"
              id="otp-input-box"
              maxLength="5"
              onChange={(e) => {
                setOTP(e.target.value);
              }}
              className="zip-input"
              placeholder="Enter Pin"
            ></input>

            <div>
              <button
                onClick={() => {
                  verifyOTP();
                }}
                type="submit"
                className="home-page-button"
              >
                Verify
              </button>
            </div>
            <p style={{marginTop:"1em"}}>
              Didn't receive it?{" "}
              <span
                onClick={(e) => {
                  submitForm(e);
                }}
                style={
                  isTimerRunning
                    ? {}
                    : {
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer",
                      }
                }
              >
                Try again{" "}
              </span>
              <br></br>
              {isTimerRunning && <span>After {otpTimerSeconds} seconds</span>}
            </p>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div className="first-page">
      {confirmOTPComponent()}
      <form
        onSubmit={(e) => {
          submitForm(e);
        }}
      >
        <div>
          <div className="first-page-input-div">
            <div>
              <label>License Number:</label>
              <br></br>
              <input
                type="text"
                className="first-page-input"
                id="license_no"
                label="License Number"
                variant="outlined"
                onChange={(e) => {
                  onChangeInput(e);
                }}
                onFocus={() => setIsLicenseNumberOnFocus(true)}
                onBlur={() => setIsLicenseNumberOnFocus(false)}
                value={licenseNumber}
                placeholder="Enter License Number"
                required={phoneNumber === "" && true}
              />
            </div>
          </div>
          {licenseNumber.length < 5 && isLicenseNumberOnFocus === true && (
            <div className="invalid-message" style={{ color: "red" }}>
              <p>Invalid License Number</p>
            </div>
          )}
          <div className="row">
            <div className="col-md-12">Or</div>
          </div>

          <div className="first-page-input-div">
            <div>
              <label>Phone Number:</label>
              <br></br>
              <input
                type="number"
                maxLength="10"
                className="first-page-input"
                id="driverphone"
                label="Phone Number"
                variant="outlined"
                onChange={(e) => {
                  onChangeInput(e);
                }}
                onFocus={() => setIsPhoneNumberOnFocus(true)}
                onBlur={() => setIsPhoneNumberOnFocus(false)}
                value={phoneNumber}
                placeholder="Enter Phone Number"
                required={licenseNumber === "" && true}
              />
            </div>
          </div>
          {phoneNumber.length < 10 && phoneNumber.length > 9 &&  isPhoneNumberOnFocus === true && (
            <div className="invalid-message" style={{ color: "red" }}>
              <p>Invalid Phone Number</p>
            </div>
          )}

          <div
            className="continue-button-confirm"
            style={{ marginBottom: "5rem", marginTop: "2rem" }}
          >
            <button type="submit">Continue</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FirstPage;

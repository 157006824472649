import React, { useState } from "react";
import "./videosCarousel.css";

// Core modules imports are same as usual
import { Navigation, Pagination } from "swiper";
// Direct React component imports
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module
import VideoThumbnail from "./videoThumbnail";

// Images
import send_message_image from "./thumbnails/send_message.jpg";
import schedule_apprisal_image from "./thumbnails/schedule_apprisal.jpg";
import report_claim_image from "./thumbnails/report_claim.jpg";
import claim_status_image from "./thumbnails/claim_status.jpg";
import check_status_image from "./thumbnails/check_status.jpg";

const VideosCarousel = () => {
  const videoSlides = () => {
    return (
      <div>
        <SwiperSlide>
          <VideoThumbnail
            text="Send message and documents to your Adjuster."
            videoUrl="https://player.vimeo.com/video/702177843?h=f698312f3a&portrait=0&byline=0&title=0&autoplay=0"
            thumbnailImage={send_message_image}
          />
        </SwiperSlide>
        <SwiperSlide>
          <VideoThumbnail
            text="Schedule an appraisal."
            videoUrl="https://player.vimeo.com/video/702177808?h=d91ccb556b&portrait=0&byline=0&title=0&autoplay=0"
            thumbnailImage={schedule_apprisal_image}
          />
        </SwiperSlide>
        <SwiperSlide>
          <VideoThumbnail
            text="Report a claim."
            videoUrl="https://player.vimeo.com/video/702177771?h=a8f4844750&portrait=0&byline=0&title=0&autoplay=0"
            thumbnailImage={report_claim_image}
          />
        </SwiperSlide>
        <SwiperSlide>
          <VideoThumbnail
            text="Check claim status if you are not a UAIC Customer."
            videoUrl="https://player.vimeo.com/video/702177740?h=6a5f753614&portrait=0&byline=0&title=0&autoplay=0"
            thumbnailImage={claim_status_image}
          />
        </SwiperSlide>
        <SwiperSlide>
          <VideoThumbnail
            text="Check the status on an existing claim."
            videoUrl="https://player.vimeo.com/video/703832189?h=3a30c086d1&portrait=0&byline=0&title=0&autoplay=0"
            thumbnailImage={check_status_image}
          />
        </SwiperSlide>
      </div>
    );
  };

  return (
    <div className="videos-carousel-component">
      <div className="videos-mobile-container">
        <Swiper
          className="swiper-component"
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          spaceBetween={10}
          slidesPerView={1}
        >
          {videoSlides()}
        </Swiper>
      </div>
      <div className="videos-desktop-container">
        <Swiper
          className="swiper-component"
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          spaceBetween={20}
          slidesPerView={1}
        >
          {videoSlides()}
        </Swiper>
      </div>
    </div>
  );
};

export default VideosCarousel;

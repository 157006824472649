import React, { useEffect, useState } from "react";
import "./404page.css";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  //load animation
  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  return (
    <div
      className={
        isComponentLoaded ? "Not-Found-Page" : "Not-Found-Page-not-rendered"
      }
    >
      <div className="Not-Found-Page-Div">
        <h1>Oops! The page you are looking for has not been found.</h1>
        <img
          alt="Not Found"
          src="/uaichome/pictures/image_2021_05_21T16_58_12_621Z.png"
        />
        <div>
          <p style={{ fontSize: "20px" }}>
            Feel free to <Link to="uaichome/contact">contact us</Link> if the
            problem persists or if you definetely can't find what you're looking
            for. Thank you very much
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;

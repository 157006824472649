import React, { useEffect, useState } from "react";
import "./insurance.css";

const Insurance = () => {
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  //load animation
  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);
  return (
    <div className={isComponentLoaded ? "insurance" : "insurance-not-rendered"}>
      <div className="insurance-box">
        <i
          style={{ marginLeft: "2rem" }}
          className="fas fa-car-crash fa-2x"
        ></i>
        <h2 className="color-blue" style={{ marginLeft: "1rem" }}>
          Insurance{" "}
        </h2>
      </div>
      <div className="insurance-info-div">
        <p>
          <strong>We understand what's important to you.</strong> You'll take
          great comfort knowing that the products and coverages that we've
          created are nothing short of the best the industry has to offer. Have
          a look, and feel free to ask your local independent UAIC agent about
          the personalized coverages and policy discounts offered in your state.
        </p>
        <br></br>
        <hr></hr>
        <div className="cards-div">
          <div className="insurance-card-1">
            <h3 className="blue-color">Automobile Insurance</h3>
            <img
              alt="Person holding red toy car"
              className="insurance-images"
              src="/uaichome/pictures/autoinsurance.png "
            />
            <p>
              Whether it's for your car, truck, or SUV, your independent
              insurance agent is ready to talk about your coverage needs and
              explain everything you need to know.
            </p>
            <div style={{ marginTop: "1rem" }}>
              <div className="insurance-list-item">
                <i
                  style={{
                    color: "rgb(56, 212, 64)",
                    marginRight: "1rem",
                    fontSize: "25px",
                  }}
                  className="fas fa-arrow-alt-circle-right "
                ></i>
                <p>Low rates & money-saving discounts.</p>
              </div>
              <div className="insurance-list-item">
                <i
                  style={{
                    color: "rgb(56, 212, 64)",
                    marginRight: "1rem",
                    fontSize: "25px",
                  }}
                  className="fas fa-arrow-alt-circle-right "
                ></i>
                <p>Personalized coverage options.</p>
              </div>
              <div className="insurance-list-item">
                <i
                  style={{
                    color: "rgb(56, 212, 64)",
                    marginRight: "1rem",
                    fontSize: "25px",
                  }}
                  className="fas fa-arrow-alt-circle-right "
                ></i>
                <p>
                  Expert advice from your local independent insurance agent.
                </p>
              </div>
              <div className="insurance-list-item">
                <i
                  style={{
                    color: "rgb(56, 212, 64)",
                    marginRight: "1rem",
                    fontSize: "25px",
                  }}
                  className="fas fa-arrow-alt-circle-right "
                ></i>
                <p>Superior claims service.</p>
              </div>
            </div>
          </div>
          <div className="insurance-card-2">
            <h3 className="blue-color">Commercial Auto Insurance</h3>
            <img
              alt="Multiple work vans parked next to each other"
              className="insurance-images"
              src="/uaichome/pictures/commercialinsurance.png"
            />
            <p style={{ marginTop: "1rem" }}>
              For more than a decade UAIC has been insuring business
              automobiles, UAIC understands just what you need and when you need
              it.
            </p>
            <div style={{ marginTop: "1rem" }}>
              <div className="insurance-list-item">
                <i
                  style={{
                    color: "rgb(56, 212, 64)",
                    marginRight: "1rem",
                    fontSize: "25px",
                  }}
                  className="fas fa-arrow-alt-circle-right "
                ></i>
                <p>Commercial Policies Available in Florida Only.</p>
              </div>
              <div className="insurance-list-item">
                <i
                  style={{
                    color: "rgb(56, 212, 64)",
                    marginRight: "1rem",
                    fontSize: "25px",
                  }}
                  className="fas fa-arrow-alt-circle-right "
                ></i>
                <p>Service personalized to suit your coverage needs.</p>
              </div>
              <div className="insurance-list-item">
                <i
                  style={{
                    color: "rgb(56, 212, 64)",
                    marginRight: "1rem",
                    fontSize: "25px",
                  }}
                  className="fas fa-arrow-alt-circle-right "
                ></i>
                <p>Competitively-priced.</p>
              </div>
              <div className="insurance-list-item">
                <i
                  style={{
                    color: "rgb(56, 212, 64)",
                    marginRight: "1rem",
                    fontSize: "25px",
                  }}
                  className="fas fa-arrow-alt-circle-right "
                ></i>
                <p>Personalized coverage options.</p>
              </div>
              <div className="insurance-list-item">
                <i
                  style={{
                    color: "rgb(56, 212, 64)",
                    marginRight: "1rem",
                    fontSize: "25px",
                  }}
                  className="fas fa-arrow-alt-circle-right "
                ></i>
                <p>
                  Coverage for more drivers, vehicle types and businesses than
                  ever before.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Insurance;

import React, { useEffect, useState } from "react";
import "../contact.css";

const Georgia = () => {
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);

  //load animation
  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  return (
    <div
      className={
        isComponentLoaded ? "contact-us-state" : "contact-us-state-not-rendered"
      }
    >
      <div className="state-div">
        <div style={{ marginRight: "1rem" }}>
          <div className="contact">
            <h4>Policy</h4>
            <hr></hr>
            <h5>Customer Service</h5>
            <ul>
              <li>M - F 8:00am - 5:00pm</li>
              <li>
                Phone: <a href="tel:7702099710">(770) 209-9710</a>
              </li>
              <li>P.O. Box 694140</li>
              <li>Miami, FL 33269-1140</li>
            </ul>
          </div>
          <br></br>
          <div className="contact">
            <h4>Agent Support and Marketing</h4>
            <hr></hr>
            <ul>
              <li>M - F 8:30am - 5:30pm</li>
              <li>
              Phone: <a href="tel:8333658242">(833) 365-8242</a>
              </li>
              {/* <li>
              Email: <a href="mailto:Agencysupport@uaig.net">Agencysupport@uaig.net</a>
              </li> */}
            </ul>
          </div>
        </div>
        <div>
          <div className="contact">
            <h4>Claims</h4>
            <hr></hr>
            <h5>Office Hours:</h5>
            <ul>
              <li>M - F 8:00am - 5:00pm</li>
            </ul>
            <h5>New Claims Report:</h5>
            <ul>
              <li>M - F 8:00am - 6:00pm EST</li>
              <li>
                Phone: <a href="tel:7702099710">(770) 209-9710</a>
              </li>
              <li>P.O. Box 694120</li>
              <li>Miami, FL 33269-1120</li>
            </ul>
          </div>
          <br></br>
          <div className="contact">
            <h4>Automated Policy Status and Payments</h4>
            <hr></hr>
            <ul>
              <li>24 Hours a Day</li>
              <li>7 Days a Week</li>
              <li>
                Phone: <a href="tel:8889878242">(888) 987-8242</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Georgia;

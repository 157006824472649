import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./claims.css";
import Florida from "../contact/contactUsComponents/Florida";
import Georgia from "../contact/contactUsComponents/Georgia";
import Texas from "../contact/contactUsComponents/Texas";
import GA from "../functions/GA";
import { useDispatch, useSelector } from "react-redux";
import selectCorrectUrls from "../../selectCorrectUrls";
const Claims = () => {
  const dispatch = useDispatch();
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  const selectedState = useSelector((state) => state.selectedContactUsTab);
  const [windowWidth, setWindowWidth] = useState();
  const [windowScrollPosition, setWindowScrollPosition] = useState(0);
  const [isMobileNavigationOpen, setIsMobileNavigationOpen] = useState(false);

  //load animation
  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
    window.addEventListener("scroll", () =>
      setWindowScrollPosition(window.scrollY)
    );
    setWindowWidth(window.innerWidth);
  }, []);

  const contactUsNavigation = () => {
    return (
      <div
        style={{
          padding: "1rem",
          borderRight: "1px solid gray",
          height: "100%",

          marginRight: "2rem",
        }}
      >
        <div
          style={{ position: "sticky", top: "100px", minWidth: "280px" }}
          className="careers-navigation"
        >
          <h3>Select your state - {selectedState}</h3>
          <hr></hr>
          <div style={{ marginLeft: "1rem" }}>
            <p
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_CONTACT_TAB",
                  payload: "FLORIDA",
                });
              }}
              className="careers-navigation-link-item"
              style={
                selectedState === "FLORIDA"
                  ? {
                      fontWeight: "bolder",
                      color: "rgb(50, 50, 150)",
                    }
                  : {}
              }
            >
              Florida
            </p>
          </div>
          <div style={{ marginLeft: "1rem" }}>
            <p
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_CONTACT_TAB",
                  payload: "GEORGIA",
                });
              }}
              className="careers-navigation-link-item"
              style={
                selectedState === "GEORGIA"
                  ? {
                      fontWeight: "bolder",
                      color: "rgb(50, 50, 150)",
                    }
                  : {}
              }
            >
              Georgia
            </p>
          </div>
          <div style={{ marginLeft: "1rem" }}>
            <p
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_CONTACT_TAB",
                  payload: "TEXAS",
                });
              }}
              className="careers-navigation-link-item"
              style={
                selectedState === "TEXAS"
                  ? {
                      fontWeight: "bolder",
                      color: "rgb(50, 50, 150)",
                    }
                  : {}
              }
            >
              Texas
            </p>
          </div>
        </div>
      </div>
    );
  };

  const contactUsNavigationMobile = () => {
    return (
      <div>
        <div
          className={
            isMobileNavigationOpen
              ? "navigation-mobile-open-contact-us"
              : "navigation-mobile-closed-contact-us"
          }
        >
          <div
            onClick={() => {
              setIsMobileNavigationOpen(!isMobileNavigationOpen);
            }}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>Select state - {selectedState}</h3>
            {isMobileNavigationOpen ? (
              <i
                style={{ marginLeft: "10px" }}
                className="fas fa-chevron-up"
              ></i>
            ) : (
              <i
                style={{ marginLeft: "10px" }}
                className="fas fa-chevron-down"
              ></i>
            )}
          </div>
          <br></br>
          <hr></hr>
          <div style={{ marginLeft: "1rem" }}>
            <p
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_CONTACT_TAB",
                  payload: "FLORIDA",
                });
                setIsMobileNavigationOpen(false);
                GA("Contact_Us", "User clicked on FLORIDA Contact Us ");
              }}
              className="careers-navigation-link-item"
              style={
                selectedState === "FLORIDA"
                  ? {
                      fontWeight: "bolder",
                      color: "rgb(50, 50, 150)",
                    }
                  : {}
              }
            >
              Florida
            </p>
          </div>

          <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
            <p
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_CONTACT_TAB",
                  payload: "GEORGIA",
                });
                setIsMobileNavigationOpen(false);
                GA("Contact_Us", "User clicked on GEORGIA Contact Us ");
              }}
              className="careers-navigation-link-item"
              style={
                selectedState === "GEORGIA"
                  ? {
                      fontWeight: "bolder",
                      color: "rgb(50, 50, 150)",
                    }
                  : {}
              }
            >
              Georgia
            </p>
          </div>
          <div style={{ marginLeft: "1rem", marginTop: "5px" }}>
            <p
              onClick={() => {
                dispatch({
                  type: "SET_SELECTED_CONTACT_TAB",
                  payload: "TEXAS",
                });
                GA("Contact_Us", "User clicked on TEXAS Contact Us ");
                setIsMobileNavigationOpen(false);
              }}
              className="careers-navigation-link-item"
              style={
                selectedState === "TEXAS"
                  ? {
                      fontWeight: "bolder",
                      color: "rgb(50, 50, 150)",
                    }
                  : {}
              }
            >
              Texas
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderSelectedComponent = () => {
    if (selectedState === "FLORIDA") {
      return <Florida />;
    } else if (selectedState === "GEORGIA") {
      return <Georgia />;
    } else if (selectedState === "TEXAS") {
      return <Texas />;
    }
  };

  return (
    <div className={isComponentLoaded ? "claims" : "claims-not-rendered"}>
      <div
        className="contact-us-box"
        style={{ marginTop: "1rem", marginBottom: "1rem" }}
      >
        <i
          style={{ marginLeft: "2rem" }}
          className="far fa-sticky-note fa-2x"
        ></i>
        <h2 style={{ marginLeft: "1rem" }}>Claims Portal</h2>
      </div>
      <div className="div-cards">
        <div className="card1">
          <a
            target="_blank"
            className="link-claims-card"
            href="https://mypolicy.uaig.net/insurednew/insured/claimuser/index"
            rel="noreferrer"
            onClick={() => {
              GA(
                "Claims_PolicyHolder",
                "User clicked on the Claims_PolicyHolder card on Claims Page"
              );
            }}
          >
            <div>
              <img
                alt="Icon of cars crashing"
                src="/uaichome/pictures/collision2.png"
              />
              <h2>I'm a UAIC Customer</h2>
              <ul style={{ textAlign: "left" }}>
                <li>File a claim</li>
                <li>View Claim(s)</li>
                <li>View Coverages</li>
                <li>Check Claim Status</li>
                <li>Send Documents</li>
                <li>Contact Your Adjuster</li>
                <li>Schedule Vehicle Damage Estimate</li>
              </ul>
              <strong>
                {" "}
                <p className="bold">Log in</p>{" "}
              </strong>
            </div>
          </a>
        </div>
        <div className="card2">
          <a
            target="_blank"
            className="link-claims-card"
            href={`${selectCorrectUrls().claims_portal}`}
            rel="noreferrer"
            onClick={() => {
              GA(
                "Claims_NonPolicyHolder",
                "User clicked on the Claims_NonPolicyHolder card on Claims Page"
              );
            }}
          >
            <div>
              <img
                alt="Icon for a search"
                src="/uaichome/pictures/TRACK2.png"
              />
              <h2>Not a UAIC Customer?</h2>
              <ul style={{ textAlign: "left" }}>
                <li>File a Claim</li>
                <li>Check Claim Status</li>
                <li>Send Documents</li>
                <li>Contact your Adjuster</li>
                <li>Schedule Vehicle Damage Estimate</li>
              </ul>
              <br></br>
              <br></br>
              <strong>
                <p className="bold">Click Here</p>
              </strong>
            </div>
          </a>
        </div>
        <Link
          style={{ textDecoration: "none" }}
          to="/uaichome/claims/faq"
          className="card3"
          onClick={() => {
            GA(
              "Claims_Faq",
              "User clicked on the Claims_Faq card on Claims Page"
            );
          }}
        >
          <div className="link-claims-card">
            <div>
              <img alt="Icon foor faq" src="/uaichome/pictures/FAQ2.png" />
              <h2>Claim FAQ</h2>
              <ul
                style={{
                  width: "250px",
                  listStyle: "none",
                  textAlign: "center",
                }}
              >
                <li>
                  <strong>New! </strong>Short videos on how to use our Claims
                  Portal
                </li>
                <br></br>
                <li>Answers to the most common questions</li>
              </ul>
              <br></br>

              <strong>
                <p style={{ marginTop: "20px" }} className="bold">
                  Click Here
                </p>
              </strong>
            </div>
          </div>
        </Link>
      </div>
      <div className="claims-info-div">
        <div>
          <h2 className="blue-color">
            What should I do if I have an accident?
          </h2>
          <hr></hr>
          <ul className="claims-accident-ul">
            <li>
              Determine if there are any injuries in any cars involved in
              accident.
            </li>
            <li>
              Remain calm. If safe, move vehicles out of traffic and turn on
              your hazard lights.
            </li>
            <li>
              Contact police and report on facts of loss. Do not admit fault or
              reveal your policy limit to anyone.
            </li>
            <li>
              Take photos of the accident scene and all vehicles involved. This
              would include skid marks, lights, lanes, intersections, and any
              other aspects of the scene.
            </li>
            <li>
              Report the incident to United Automobile Insurance as soon as
              possible.{" "}
              <strong>You can file a claim with us online, 24/7!</strong>
            </li>
          </ul>
        </div>
        <br></br>
        <br></br>
        <div>
          <h2 className="blue-color">How do I report a claim?</h2>
          <hr></hr>
          <p>
            <strong>You have Two Options</strong>
          </p>
          <ul>
            <li>
              If you have a policy with United Auto, you can now use our{" "}
              <strong>newly launched digital claims portal</strong> to file
              claims and track status updates 24/7 from any device.{" "}
              <strong>
                <a href="https://myclaim.uaig.net">Start here</a>!
              </strong>
            </li>
            <li>
              If you are not our customer, but were involved in an accident with
              an United Auto policyholder, you can can now report claim online.{" "}
              {""}
              <strong>
                <a href={`${selectCorrectUrls().claims_portal}`}>Start here</a>!
              </strong>
            </li>
            <li>
              You can also report your claim via our toll-free number during
              regular business hours by calling{" "}
              <strong>
                <a href="tel:+8003442150">800 344 2150</a>
              </strong>
              .
            </li>
          </ul>
        </div>
        <br></br>
        <br></br>
        <div>
          <h2 className="blue-color">What is the status of my claim?</h2>
          <hr></hr>
          <ul>
            <li>
              If you have a policy with United Auto, you can now use our newly
              launched digital claims portal to file claims and track status
              updates 24/7 from any device.{" "}
              <strong>
                <a href="https://myclaim.uaig.net">Start here</a>!
              </strong>
            </li>
            <li>
              All parties can use our inquiry portal to check claim status, or
              submit claim inquiries, and/or documents to adjuster handling your
              claim. It is available 24/7.{" "}
              <strong>
                <a href={`${selectCorrectUrls().claims_portal}`}>Start here</a>!
              </strong>
            </li>
          </ul>
        </div>
        <br></br>
        <br></br>
        <div>
          <h2 className="blue-color">
            What information do I need to know to report a claim?
          </h2>

          <ul>
            <li>U.A.I.C policy number, For example, UAU 000000000</li>
            <li>
              Name, Insurance information and phone number of anyone else in the
              accident with you
            </li>
            <li>Police report number and department name</li>
            <li>The date, time and place of the accident</li>
            <li>Knowledge of what happened in the loss</li>
            <li>Location of your vehicle</li>
          </ul>
        </div>
      </div>
      <div>
        <div className="contact-us-box">
          <h2 style={{ marginLeft: "2rem" }}>Contact us </h2>
        </div>
        <div className="contact-us-container">
          <div>
            {windowWidth >= 900
              ? contactUsNavigation()
              : contactUsNavigationMobile()}
          </div>
          {renderSelectedComponent()}
        </div>
      </div>
    </div>
  );
};

export default Claims;

// import { act } from "react-dom/test-utils";
import { combineReducers } from "redux";

const isLoginMenuOpenReducer = (state = false, action) => {
  switch (action.type) {
    case "OPEN_LOGIN_MENU":
      return !state;
    default:
      return state;
  }
};
const isMobileMenuOpenReducer = (state = false, action) => {
  switch (action.type) {
    case "OPEN_MOBILE_MENU":
      return !state;
    default:
      return state;
  }
};
const isAgentsLoginMenuOpenReducer = (state = false, action) => {
  switch (action.type) {
    case "OPEN_AGENTS_LOGIN_MENU":
      return !state;
    default:
      return state;
  }
};
const isCustomerLoginMenuOpenReducer = (state = false, action) => {
  switch (action.type) {
    case "OPEN_CUSTOMER_LOGIN_MENU":
      return !state;
    default:
      return state;
  }
};

const selectedCareersTab = (state = "Careers", action) => {
  switch (action.type) {
    case "SET_SELECTED_CAREERS_TAB":
      return action.payload;
    default:
      return state;
  }
};

const selectedContactUsTab = (state = "FLORIDA", action) => {
  switch (action.type) {
    case "SET_SELECTED_CONTACT_TAB":
      return action.payload;
    default:
      return state;
  }
};

const selectedPagetoDisplayReducer = (state = "Home_Page", action) => {
  switch (action.type) {
    case "SET_SELECTED_PAGE_TO_DISPLAY":
      return action.payload;
    default:
      return state;
  }
};

const selectedLogintoDisplayReducer = (state = "BOTH", action) => {
  switch (action.type) {
    case "SET_LOGIN_PAGE_TO_DISPLAY":
      return action.payload;
    default:
      return state;
  }
};

const messageErrorReducer = (state = false, action) => {
  switch (action.type) {
    case "SET_ERROR_MESSAGE":
      return action.payload;
    default:
      return state;
  }
};

const messageSuccessReducer = (state = false, action) => {
  switch (action.type) {
    case "SET_SECCESS_MESSAGE":
      return action.payload;
    default:
      return state;
  }
};

const messageReducer = (state = "This is the message", action) => {
  switch (action.type) {
    case "SET_MESSAGE":
      return action.payload;
    default:
      return state;
  }
};

const requestHelpReducer = (state = false, action) => {
  switch (action.type) {
    case "SET_REQUEST_HELP":
      return action.payload;
    default:
      return state;
  }
};
const requestHelpReasonReducer = (state = "", action) => {
  switch (action.type) {
    case "SET_REQUEST_HELP_REASON":
      return action.payload;
    default:
      return state;
  }
};

const campaignReducer = (state = "h", action) => {
  switch (action.type) {
    case "SET_CAMPAIGN":
      return action.payload;
    default:
      return state;
  }
};

const isLogedInReducer = (state = false, action) => {
  switch (action.type) {
    case "SET_IS_LOGED_IN":
      return action.payload;
    default:
      return state;
  }
};

const jwtReducer = (state = null, action) => {
  switch (action.type) {
    case "SET_JWT":
      return action.payload;
    default:
      return state;
  }
};
const contactUsReducer = (state = "regular", action) => {
  switch (action.type) {
    case "SET_CONTACT_TYPE":
      return action.payload;
    default:
      return state;
  }
};
const contactUsNumberReducer = (state = null, action) => {
  switch (action.type) {
    case "SET_CONTACT_NUMBER":
      return action.payload;
    default:
      return state;
  }
};
const insuredNumberReducer = (state = null, action) => {
  switch (action.type) {
    case "SET_INSURED_NUMBER":
      return action.payload;
    default:
      return state;
  }
};

const contactUsNameReducer = (state = null, action) => {
  switch (action.type) {
    case "SET_CONTACT_NAME":
      return action.payload;
    default:
      return state;
  }
};
const policyStatusReducer = (state = null, action) => {
  switch (action.type) {
    case "SET_POLICY_STATUS":
      return action.payload;
    default:
      return state;
  }
};

const agentInfoReducer = (
  state = { agentName: "", agentPhone: "" },
  action
) => {
  switch (action.type) {
    case "SET_AGENT_INFO":
      return action.payload;
    default:
      return state;
  }
};

const agentLocatorZipCodeReducer = ( state = null, action) => {
  switch (action.type) {
    case "SET_AGENT_LOCATOR_ZIP":
      return action.payload;
    default:
      return state;
  }
};
const agentLocatorInfoReducer = ( state = { agent1: "", agent2: "",agent3: "", }, action) => {
  switch (action.type) {
    case "SET_AGENT_LOCATOR_INFO":
      return action.payload;
    default:
      return state;
  }
};

const selectedLogoReducer = ( state = "FL", action) => {
  switch (action.type) {
    case "SET_LOGO":
      return action.payload;
    default:
      return state;
  }
};

const allReducers = combineReducers({
  isLoginMenuOpenReducer,
  isMobileMenuOpenReducer,
  isAgentsLoginMenuOpenReducer,
  isCustomerLoginMenuOpenReducer,
  messageErrorReducer,
  messageSuccessReducer,
  messageReducer,
  selectedCareersTab,
  selectedContactUsTab,
  selectedPagetoDisplayReducer,
  selectedLogintoDisplayReducer,
  requestHelpReducer,
  requestHelpReasonReducer,
  campaignReducer,
  isLogedInReducer,
  jwtReducer,
  contactUsReducer,
  contactUsNumberReducer,
  contactUsNameReducer,
  policyStatusReducer,
  insuredNumberReducer,
  agentInfoReducer,
  agentLocatorInfoReducer,
  agentLocatorZipCodeReducer,
});

export default allReducers;
